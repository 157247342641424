import React from 'react';
import PropTypes from 'prop-types';
import { Card, Button } from 'react-bootstrap';

import { useSelector, useDispatch } from 'react-redux';
import {
  atcStatus,
  setAtcStatus,
  refreshPickupCode
} from 'redux/slices/atcSlice';

const AtcQuickActions = ({ atc }) => {
  const { orgProfile } = useSelector(state => state.config);
  const dispatch = useDispatch();

  const getMerchantQuickActions = () => {
    if (orgProfile === 'merchant') {
      return (
        <>
          <Button
            size="sm"
            variant="outline-primary"
            disabled={atc.status !== atcStatus.approvedByMerchant}
            onClick={() => {
              dispatch(
                setAtcStatus({
                  atcId: atc.id,
                  newStatus: atcStatus.releasedToBank
                })
              );
              dispatch(
                refreshPickupCode({
                  atcId: atc.id
                })
              );
            }}
          >
            Release To Bank
          </Button>
        </>
      );
    }
  };

  const getBankQuickActions = () => {
    if (orgProfile === 'bank') {
      return (
        <>
          <Button
            size="sm"
            variant="outline-primary"
            disabled={atc.status !== atcStatus.approvedByBank}
            onClick={() =>
              dispatch(
                setAtcStatus({
                  atcId: atc.id,
                  newStatus: atcStatus.releasedToDistributor
                })
              )
            }
          >
            Release To Distributor
          </Button>
        </>
      );
    }
  };

  const getDistributorQuickActions = () => {
    if (orgProfile === 'distributor') {
      return (
        <>
          <Button
            size="sm"
            variant="outline-primary"
            disabled={atc.status !== atcStatus.releasedToDistributor}
            onClick={() =>
              dispatch(
                setAtcStatus({
                  atcId: atc.id,
                  newStatus: atcStatus.closed
                })
              )
            }
          >
            Confirm Product Pickup
          </Button>
        </>
      );
    }
  };

  return (
    <>
      <Card className="my-3">
        <Card.Body>
          <div className="d-flex gap-2">
            <div className="fw-bold">Quick Actions:</div>
            <div className="d-flex gap-2 justify-content-end">
              {getMerchantQuickActions()}
              {getBankQuickActions()}
              {getDistributorQuickActions()}
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

AtcQuickActions.propTypes = {
  atc: PropTypes.shape({
    id: PropTypes.number.isRequired,
    bank: PropTypes.object.isRequired,
    merchant: PropTypes.object.isRequired,
    distributor: PropTypes.object.isRequired,
    product: PropTypes.shape({
      productName: PropTypes.string.isRequired,
      productId: PropTypes.string.isRequired,
      quantityUnit: PropTypes.string.isRequired,
      logo: PropTypes.string.isRequired
    }).isRequired,
    totalQuantity: PropTypes.number.isRequired,
    issuedOn: PropTypes.string.isRequired,
    merchantApprovalWorkflow: PropTypes.array.isRequired,
    bankIssuingWorkflow: PropTypes.array.isRequired,
    status: PropTypes.number.isRequired,
    pickupCode: PropTypes.string
  })
};

export default AtcQuickActions;
