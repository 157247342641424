import Calendar from 'components/common/Calendar';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const TodoPreviewItem = ({ details, isLast }) => {
  const { calendar, title, due, interested } = details;
  return (
    <Flex>
      <Calendar {...calendar} />
      <div className="flex-1 position-relative ps-3">
        <h6 className="fs-0 mb-0">
          <Link to="/events/event-detail">
            <span className="me-1">{title}</span>
          </Link>
        </h6>
        <p className="text-1000 mb-0">Due: {due}</p>

        {interested && <p className="text-1000 mb-0">{interested}</p>}
        {!isLast && <div className="border-dashed border-bottom my-3"></div>}
      </div>
    </Flex>
  );
};

TodoPreviewItem.propTypes = {
  details: PropTypes.shape({
    calendar: PropTypes.shape(Calendar.propTypes),
    title: PropTypes.string.isRequired,
    due: PropTypes.string.isRequired,
    interested: PropTypes.string,
    badge: PropTypes.object
  }),
  isLast: PropTypes.bool
};

export default TodoPreviewItem;
