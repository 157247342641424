import React from 'react';
import { Row, Col } from 'react-bootstrap';
import PageGreeting from 'components/common/PageGreeting';

const proflieQuickLinks = [
  {
    title: 'Billing',
    text: 'See what you spend',
    icon: 'wallet',
    color: 'primary'
  },
  {
    title: 'See other apps',
    text: 'See our recommended apps for your Organization! ',
    icon: 'question',
    color: 'warning'
  }
];

const OrganizationProfilePage = () => {
  return (
    <>
      <Row className="g-3 mb-3">
        <Col>
          <PageGreeting
            title="Organization Profile"
            subtitle="Here are some links to get you started,"
            quickLinks={proflieQuickLinks}
          />
        </Col>
      </Row>
    </>
  );
};

export default OrganizationProfilePage;
