import React from 'react';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import { Link } from 'react-router-dom';

import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import { Card } from 'react-bootstrap';
import { branches } from 'data/organizations/branches';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AllBranchesTableHeader from './allBranchesTableHeader';

const columns = [
  {
    accessor: 'branchName',
    Header: 'Branch Name',
    headerProps: { className: 'ps-2', style: { height: '46px' } },
    cellProps: {
      className: 'py-2 white-space-nowrap pe-3 pe-xxl-4 ps-2'
    },
    Cell: rowData => {
      const { branchName } = rowData.row.original;
      return (
        <Flex alignItems="center" className="position-relative py-1">
          <Avatar size="xl" name={branchName} className="me-2" />
          <h6 className="mb-0">
            <Link
              to="/support-desk/contact-details"
              className="stretched-link text-900"
            >
              {branchName}
            </Link>
          </h6>
        </Flex>
      );
    }
  },
  {
    accessor: 'memberCount',
    Header: 'Members',
    headerProps: { style: { minWidth: '14.625rem' } },
    cellProps: {
      className: 'py-2 pe-4'
    },
    Cell: rowData => {
      const { memberCount } = rowData.row.original;
      return <span className="text-right">{memberCount}</span>;
    }
  }
];

const AllBranchesTable = () => {
  return (
    <AdvanceTableWrapper
      columns={columns}
      data={branches}
      selection
      selectionColumnWidth={52}
      sortable
      pagination
      perPage={10}
      rowCount={branches.length}
    >
      <Card>
        <Card.Header className="border-bottom border-200 px-0">
          <AllBranchesTableHeader table />
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-light text-800 align-middle"
            rowClassName="btn-reveal-trigger align-middle"
            tableProps={{
              size: 'sm',
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Card.Footer>
          <AdvanceTablePagination table />
        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
  );
};

export default AllBranchesTable;
