import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Navbar, Nav } from 'react-bootstrap';
import classNames from 'classnames';
import Logo from 'components/common/Logo';
import SearchBox from '../common/SearchBox';
import autoCompleteInitialItem from 'data/autocomplete/autocomplete';
import TopNavRightSideNavItem from './TopNavRightSideNavItem';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const NavbarTop = () => {
  const navbarCollapsed = useSelector(state => state.theme.navbarCollapsed);

  const { pathname } = useLocation();
  const isChat = pathname.includes('chat');

  const [showDropShadow, setShowDropShadow] = useState(false);

  const setDropShadow = () => {
    const el = document.documentElement;
    if (el.scrollTop > 0) {
      setShowDropShadow(true);
    } else {
      setShowDropShadow(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', setDropShadow);
    return () => window.removeEventListener('scroll', setDropShadow);
  }, []);

  const burgerMenuRef = useRef();

  return (
    <Navbar
      className={classNames('navbar-glass fs--1 navbar-top sticky-kit', {
        // 'navbar-glass-shadow': showDropShadow
        'navbar-glass-shadow': showDropShadow && !isChat
      })}
      expand={true}
    >
      <NavbarTopElements
        navbarCollapsed={navbarCollapsed}
        burgerMenuRef={burgerMenuRef}
      />
    </Navbar>
  );
};

const NavbarTopElements = ({ handleBurgerMenu }) => {
  const burgerMenuRef = useRef();
  return (
    <>
      <Navbar.Toggle
        ref={burgerMenuRef}
        className={classNames('toggle-icon-wrapper me-md-3 me-2', 'd-xl-none')}
        as="div"
      >
        <button
          className="navbar-toggler-humburger-icon btn btn-link d-flex flex-center"
          onClick={handleBurgerMenu}
          id="burgerMenu"
        >
          <span className="navbar-toggle-icon">
            <span className="toggle-line" />
          </span>
        </button>
      </Navbar.Toggle>

      <Logo at="navbar-top" width={40} id="topLogo" />

      <Nav navbar className="align-items-center d-none d-lg-block" as="ul">
        <Nav.Item as="li">
          <SearchBox autoCompleteItem={autoCompleteInitialItem} />
        </Nav.Item>
      </Nav>
      <TopNavRightSideNavItem />
    </>
  );
};

NavbarTopElements.propTypes = {
  handleBurgerMenu: PropTypes.func
};
export default NavbarTop;
