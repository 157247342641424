import React from 'react';
import { Row, Col } from 'react-bootstrap';
import PageGreeting from 'components/common/PageGreeting';
import AllBranchesTable from './allBranchesTable';

const branchesQuickLinks = [
  {
    title: 'Reports',
    text: 'Analyze with a few clicks',
    icon: 'chart-line',
    color: 'primary'
  },
  {
    title: 'How to use Branches?',
    text: 'See our recommended usecase for branches! ',
    icon: 'question',
    color: 'warning'
  }
];

const OrganizationBranchesPage = () => {
  return (
    <>
      <Row className="g-3 mb-3">
        <Col>
          <PageGreeting
            title="Organization Branches Home"
            subtitle="Here are some links to get you started,"
            quickLinks={branchesQuickLinks}
          />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col>
          <AllBranchesTable />
        </Col>
      </Row>
    </>
  );
};

export default OrganizationBranchesPage;
