import dangote from 'assets/img/merchants/dangote.png';
import nigerianBreweries from 'assets/img/merchants/nigerian-breweries.jpeg';
import chiLimited from 'assets/img/merchants/chi-limited.jpg';
import globusBank from 'assets/img/banks/globus-bank.jpeg';
import basicDistributor from 'assets/img/distributors/basic_distributor.jpeg';

import dangoteFalcon from 'assets/img/merchants/products/dangote-falcon.png';
import dangoteBlockMaster from 'assets/img/merchants/products/dangote-block-master.png';
import dangote3X425R from 'assets/img/merchants/products/dangote-3x-425R.png';
import dangote3X425N from 'assets/img/merchants/products/dangote-3x-425N.png';

export const distributors = {
  1: {
    id: 1,
    name: 'South East Distributor',
    logo: basicDistributor
  },
  2: {
    id: 2,
    name: 'South West Distributor',
    logo: basicDistributor
  },
  3: {
    id: 3,
    name: 'Northern Distributor',
    logo: basicDistributor
  },
  4: {
    id: 4,
    name: 'Middle Belt Distributor',
    logo: basicDistributor
  }
};

export const merchants = {
  1: {
    id: 1,
    name: 'Dangote Cement',
    logo: dangote,
    products: {
      1: {
        id: 1,
        productName: 'Dangote 3X 42.5R',
        productId: 'CEM3425R',
        quantityUnit: 'Bags',
        logo: dangote3X425R
      },
      2: {
        id: 2,
        productName: 'Dangote 3X 42.5N',
        productId: 'CEM3425N',
        quantityUnit: 'Bags',
        logo: dangote3X425N
      },
      3: {
        id: 3,
        productName: 'Dangote BlocMaster',
        productId: 'CEMBLOCK',
        quantityUnit: 'Bags',
        logo: dangoteBlockMaster
      },
      4: {
        id: 4,
        productName: 'Dangote Falcon',
        productId: 'CEMFALC',
        quantityUnit: 'Bags',
        logo: dangoteFalcon
      }
    }
  },
  2: {
    id: 2,
    name: 'Dangote Sugar',
    logo: dangote,
    products: {}
  },
  3: {
    id: 3,
    name: 'Dangote Oil and Gas',
    logo: dangote,
    products: {}
  },
  4: {
    id: 4,
    name: 'Nigerian Breweries',
    logo: nigerianBreweries,
    products: {}
  },
  5: {
    id: 5,
    name: 'Chi Limited',
    logo: chiLimited,
    products: {}
  }
};

export const banks = {
  1: {
    id: 1,
    name: 'Globus Bank',
    logo: globusBank
  }
};
