import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const Footer = () => {
  const version = useSelector(state => state.config.version);
  return (
    <footer className="footer">
      <Row className="justify-content-between text-center fs--1 mt-4 mb-3">
        <Col sm="auto">
          <p className="mb-0 text-600">
            Thank you for using a Tafi Casa app{' '}
            <span className="d-none d-sm-inline-block">| </span>
            <br className="d-sm-none" /> {new Date().getFullYear()} &copy;{' '}
            TafiCasa
          </p>
        </Col>
        <Col sm="auto">
          <p className="mb-0 text-600">v{version}</p>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
