import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const RequireAuth = ({ children }) => {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  return isAuthenticated ? children : <Navigate to="/app/login" />;
};

RequireAuth.propTypes = {
  children: PropTypes.node.isRequired
};

export default RequireAuth;
