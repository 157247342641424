import { v4 as uuid } from 'uuid';

export default [
  {
    id: uuid(),
    calendar: { month: 'Apr', day: '26' },
    title: 'Review PH Cement Loan Application',
    badge: {
      title: 'Free',
      type: 'soft-success'
    },
    due: '11:00AM, Apr 26'
  },
  {
    id: uuid(),
    calendar: { month: 'May', day: '29' },
    title: 'Issue Loan Offer to Kano Cement',
    due: '1:00PM, May 29'
  },
  {
    id: uuid(),
    calendar: { month: 'Jun', day: '5' },
    title: 'Approve Pickup Authorization ',
    due: '5:00PM, Jun 5'
  }
];
