import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import PageGreeting from 'components/common/PageGreeting';

import BankAtcSearch from './bank/bankAtcSearch';
import MerchantAtcSearch from './merchant/merchantAtcSearch';
import DistributorAtcSearch from './distributors/distributorAtcSearch';
import ActionNeededAtcs from './common/actionNeededAtcs';

const AtcCenterPage = () => {
  const { orgProfile, orgId } = useSelector(state => state.config);

  const getQuickLinks = () => {
    let quickLinks = [];

    if (orgProfile === 'merchant') {
      quickLinks.push({
        title: 'Create ATC',
        text: 'Create and Issue ATCs',
        icon: 'chart-line',
        color: 'primary',
        link: '/app/supplychain/atc/create'
      });
    }

    quickLinks.push({
      title: 'Help?',
      text: 'Tips for using ATC center ',
      icon: 'question',
      color: 'warning',
      link: '#'
    });
    return quickLinks;
  };
  const getAtcSearch = orgProfile => {
    if (orgProfile === 'bank') {
      return <BankAtcSearch bankId={orgId} />;
    } else if (orgProfile === 'merchant') {
      return <MerchantAtcSearch merchantId={orgId} />;
    } else if (orgProfile === 'distributor') {
      return <DistributorAtcSearch distributorId={orgId} />;
    }

    return null;
  };

  return (
    <>
      <Row className="g-3 mb-3">
        <Col>
          <PageGreeting
            title="ATC Center Home"
            subtitle="Here are some links to get you started,"
            quickLinks={getQuickLinks()}
          />
        </Col>
        <Col>
          <ActionNeededAtcs />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col>{getAtcSearch(orgProfile)}</Col>
      </Row>
    </>
  );
};

export default AtcCenterPage;
