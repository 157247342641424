import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';
import classNames from 'classnames';

const PasswordResetPage = () => {
  // State
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: ''
  });

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    toast.success('Login with your new password', {
      theme: 'colored'
    });
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <>
      <div className="text-center">
        <h3>Reset password</h3>
      </div>
      <Form className={classNames('mt-3', 'text-left')} onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>New Password</Form.Label>
          <Form.Control
            placeholder=""
            value={formData.password}
            name="password"
            onChange={handleFieldChange}
            type="password"
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            placeholder=""
            value={formData.confirmPassword}
            name="confirmPassword"
            onChange={handleFieldChange}
            type="password"
          />
        </Form.Group>

        <Button
          type="submit"
          className="w-100"
          disabled={!formData.password || !formData.confirmPassword}
        >
          Set password
        </Button>
      </Form>
    </>
  );
};

export default PasswordResetPage;
