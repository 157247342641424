import React, { useState } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { Link, useNavigate } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { setOrgProfileAndId } from 'redux/slices/configSlice';
import { clearAtcSearchState } from 'redux/slices/atcSlice';

import { Card, Form, Dropdown, Button, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { distributors, banks, merchants } from 'data/supply-chain/common';

const ChangeOrganizationProfile = () => {
  const { orgProfile, orgId } = useSelector(state => state.config);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const [currentOrgProfile, setCurrentOrgProfile] = useState(orgProfile);
  const [currentEntity, setCurrentEntity] = useState(orgId);

  // Handler
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const onClickSave = () => {
    dispatch(clearAtcSearchState());
    dispatch(
      setOrgProfileAndId({
        profileName: currentOrgProfile,
        profileId: currentEntity
      })
    );
    setIsOpen(false);
    navigate('/app/supplychain/atc');
  };

  const onOrgProfileSelect = profileName => {
    setCurrentOrgProfile(profileName);
    setCurrentEntity(1);
  };

  const onOrgEntitySelect = id => {
    const idAsNumber = parseInt(id);
    setCurrentEntity(idAsNumber);
  };

  const getEntitiesFromProfile = profileName => {
    if (profileName === 'bank') {
      return banks;
    } else if (profileName === 'distributor') {
      return distributors;
    } else if (profileName === 'merchant') {
      return merchants;
    }
    return {};
  };

  const getOrgProfileBadge = orgProfile => {
    if (orgProfile === 'bank') {
      return (
        <Badge pill bg="primary" className="ms-2">
          Bank
        </Badge>
      );
    } else if (orgProfile === 'distributor') {
      return (
        <Badge pill bg="success" className="ms-2">
          Distributor
        </Badge>
      );
    } else if (orgProfile === 'merchant') {
      return (
        <Badge pill bg="warning" className="ms-2">
          Merchant
        </Badge>
      );
    }
    return null;
  };

  return (
    <>
      <div className="d-flex align-items-center">
        Viewing As: {getEntitiesFromProfile(orgProfile)[orgId].name}
        {getOrgProfileBadge(orgProfile)}
      </div>
      <div className="vr my-auto mx-2 bg-secondary"></div>
      <Dropdown navbar={true} as="li" show={isOpen} onToggle={handleToggle}>
        <Dropdown.Toggle
          bsPrefix="toggle"
          as={Link}
          to="#!"
          className={classNames('px-0 nav-link')}
        >
          <FontAwesomeIcon icon="cog" transform="shrink-6" className="fs-4" />
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-menu-card dropdown-menu-end dropdown-caret dropdown-caret-bg">
          <Card className="container-fluid d-flex">
            <Card.Body
              className="text-nowrap d-flex flex-column"
              style={{ width: '27.5rem' }}
            >
              <h5>Change App Demo Settings</h5>
              <hr className="mt-1" />
              <div className="d-flex justify-content-between align-items-center mb-2">
                <h6 className="me-2 my-auto">Switch Org Profile:</h6>
                <Form.Select
                  size="sm"
                  value={currentOrgProfile}
                  onChange={({ target }) => onOrgProfileSelect(target.value)}
                >
                  {['bank', 'distributor', 'merchant'].map(option => (
                    <option key={option} value={option}>
                      {_.capitalize(option)}
                    </option>
                  ))}
                </Form.Select>
              </div>
              <div className="d-flex justify-content-between align-items-center mb-2">
                <h6 className="me-2 my-auto">Select Current Entity:</h6>
                <Form.Select
                  size="sm"
                  value={currentEntity}
                  onChange={({ target }) => onOrgEntitySelect(target.value)}
                >
                  {Object.values(getEntitiesFromProfile(currentOrgProfile)).map(
                    entity => (
                      <option
                        key={`app-demo-entity-option-${entity.id}`}
                        value={entity.id}
                      >
                        {_.capitalize(entity.name)}
                      </option>
                    )
                  )}
                </Form.Select>
              </div>
              <div className="mt-3 d-flex justify-content-end">
                <Button variant="primary" onClick={onClickSave}>
                  Save Changes
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default ChangeOrganizationProfile;
