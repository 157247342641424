import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { atcStatus } from 'redux/slices/atcSlice';

import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ActionNeededAtcs = () => {
  const navigate = useNavigate();
  const columns = React.useMemo(
    () => [
      {
        accessor: 'atcId',
        Header: 'ATC',
        Cell: rowData => {
          const { atcId, action } = rowData.row.original;
          return (
            <div className="d-flex gap-2 justify-content-between">
              <div className="text-dark fw-bold">ATC #{atcId}</div>
              <div className="text-danger">
                <FontAwesomeIcon icon="exclamation-circle" />
                <span className="ms-2">{action}</span>
              </div>
            </div>
          );
        }
      }
    ],
    []
  );
  const { orgProfile, orgId } = useSelector(state => state.config);
  const { atcs } = useSelector(state => state.atc);

  const rowIdResolver = row => {
    const { atcId } = row;
    return atcId;
  };

  const onRowClick = atcId => {
    navigate(`/app/supplychain/atc/view/${atcId}`);
  };

  const getData = () => {
    let data = [];

    if (orgProfile === 'merchant') {
      Object.values(atcs).forEach(atc => {
        if (atc.merchant.id === orgId && atc.status === atcStatus.created) {
          data.push({
            atcId: atc.id,
            action: 'Pending Internal Approval'
          });
        } else if (
          atc.merchant.id === orgId &&
          atc.status === atcStatus.approvedByMerchant
        ) {
          data.push({
            atcId: atc.id,
            action: 'Approved, Pending Issue to Bank'
          });
        }
      });
    } else if (orgProfile === 'bank') {
      Object.values(atcs).forEach(atc => {
        if (atc.bank.id === orgId && atc.status === atcStatus.releasedToBank) {
          data.push({
            atcId: atc.id,
            action: 'Pending Internal Approval'
          });
        } else if (
          atc.bank.id === orgId &&
          atc.status === atcStatus.approvedByBank
        ) {
          data.push({
            atcId: atc.id,
            action: 'Approved, Pending Issue to Distributor'
          });
        }
      });
    } else if (orgProfile === 'distributor') {
      Object.values(atcs).forEach(atc => {
        if (
          atc.distributor.id === orgId &&
          atc.status === atcStatus.releasedToDistributor
        ) {
          data.push({
            atcId: atc.id,
            action: 'Rceived, Pending Pickup'
          });
        }
      });
    }

    return data;
  };

  return (
    <AdvanceTableWrapper
      columns={columns}
      data={getData()}
      sortable
      pagination
      perPage={3}
      getRowId={rowIdResolver}
    >
      <Card className="h-100">
        <Card.Header>
          <Row className="flex-between-center">
            <Col xs="auto" sm={6} lg={7}>
              <h6 className="mb-0 text-nowrap py-2 py-xl-0">Action Needed</h6>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              bordered: true,
              striped: true,
              hover: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
            onRowClick={onRowClick}
          />
        </Card.Body>
        <Card.Footer>
          <AdvanceTableFooter
            rowCount={getData().length}
            table
            rowInfo
            navButtons
          />
        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
  );
};

export default ActionNeededAtcs;
