import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  version: '0.0.1',
  orgProfile: 'bank',
  orgId: 1
};

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setOrgProfileAndId: (state, action) => {
      state.orgProfile = action.payload.profileName;
      state.orgId = action.payload.profileId;
    }
  }
});

export const { setOrgProfileAndId } = configSlice.actions;

export default configSlice;
