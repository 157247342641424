import React from 'react';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const SubPageLayout = ({ children, back, title, buttons }) => {
  const navigate = useNavigate();

  return (
    <div className="container-fluid">
      <div className="mb-3 position-sticky">
        <Card>
          <Card.Header>
            <Flex direction="row" justifyContent="between" alignItems="end">
              <IconButton
                onClick={() => navigate(back)}
                variant="falcon-default"
                size="sm"
                icon="arrow-left"
              />
              <h5>{title}</h5>
            </Flex>
            <hr />
            <Flex direction="row" justifyContent="end" alignItems="center">
              {buttons.map(button => {
                return (
                  <IconButton
                    key={button.name}
                    onClick={button.onClick}
                    variant={button.variant || 'falcon-default'}
                    size={button.size || 'sm'}
                    icon={button.icon}
                    transform={button.transform || 'shrink-2'}
                    iconAlign={button.iconAlign || 'middle'}
                    className="ms-3"
                  >
                    <span className="ms-1">{button.name}</span>
                  </IconButton>
                );
              })}
            </Flex>
          </Card.Header>
        </Card>
      </div>
      {children}
    </div>
  );
};

SubPageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  back: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      variant: PropTypes.string,
      size: PropTypes.string,
      icon: PropTypes.string.isRequired,
      transform: PropTypes.string,
      iconAlign: PropTypes.string,
      className: PropTypes.string
    })
  )
};

export default SubPageLayout;
