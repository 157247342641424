import React from 'react';
import { Card } from 'react-bootstrap';

const HomePage = () => {
  return (
    <Card>
      <Card.Body className="p-0">HomePage</Card.Body>
    </Card>
  );
};

export default HomePage;
