import React from 'react';
import { Row, Col } from 'react-bootstrap';
import PageGreeting from 'components/common/PageGreeting';
import AllTeamsTable from './allTeamsTable';

const teamsQuickLinks = [
  {
    title: 'Reports',
    text: 'Analyze with a few clicks',
    icon: 'chart-line',
    color: 'primary'
  },
  {
    title: 'How to use Teams?',
    text: 'See our recommended usecase for teams! ',
    icon: 'question',
    color: 'warning'
  }
];

const OrganizationTeamsPage = () => {
  return (
    <>
      <Row className="g-3 mb-3">
        <Col>
          <PageGreeting
            title="Organization Teams Home"
            subtitle="Here are some links to get you started,"
            quickLinks={teamsQuickLinks}
          />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col>
          <AllTeamsTable />
        </Col>
      </Row>
    </>
  );
};

export default OrganizationTeamsPage;
