import React, { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import is from 'is_js';
import getRouter from 'routes';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import { getColor } from 'helpers/utils';
import useToggleStyle from './hooks/useToggleStyle';
import { useSelector } from 'react-redux';

import { Chart as ChartJS, registerables } from 'chart.js';
ChartJS.register(...registerables);

const App = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  const isDarkMode = useSelector(state => state.theme.isDarkMode);
  const orgProfile = useSelector(state => state.config.orgProfile);

  const { isLoaded } = useToggleStyle(isDarkMode);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
    if (is.safari()) {
      HTMLClassList.add('safari');
    }
  }, [HTMLClassList]);

  if (!isLoaded) {
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: isDarkMode ? getColor('dark') : getColor('light')
        }}
      />
    );
  }

  return <RouterProvider router={getRouter(orgProfile)}></RouterProvider>;
};

export default App;
