import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { useSelector } from 'react-redux';
import { atcStatus } from 'redux/slices/atcSlice';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import { Card, Row, Col, Image, Button, Modal, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';

import student from 'assets/img/e-learning/avatar/student.png';

import PickupCode from './pickupCode';

const pickupPersonnelData = [
  {
    employeeId: '23864580',
    name: 'Employee 1',
    email: 'employee1@company.com',
    phoneNumber: '+234 80 485 63484'
  },
  {
    employeeId: '23864581',
    name: 'Employee 2',
    email: 'employee2@company.com',
    phoneNumber: '+234 80 485 63484'
  },
  {
    employeeId: '23864582',
    name: 'Employee 3',
    email: 'employee3@company.com',
    phoneNumber: '+234 80 485 63484'
  }
];

const ProductPickupTabPane = ({ atc }) => {
  const orgProfile = useSelector(state => state.config.orgProfile);
  const [showChangePickupModal, setShowChangePickupModal] = useState(false);
  const [currentEmployee, setCurrentEmployee] = useState(0);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const onSubmitChangePickup = data => {
    setCurrentEmployee(data.selectPcikupPerson);
    toast('Change Successful!', { type: 'success' });
    closeChangePickupModal();
  };

  const openChangePickupModal = () => {
    setShowChangePickupModal(true);
  };

  const closeChangePickupModal = () => {
    setShowChangePickupModal(false);
  };

  const getDistributorChangePickupButton = () => {
    if (atc.status === atcStatus.releasedToDistributor) {
      return (
        <div className="d-flex justify-content-end align-items-center mt-2">
          <Button
            variant="outline-danger"
            className="ms-2"
            onClick={openChangePickupModal}
          >
            Change
          </Button>
        </div>
      );
    }
    return null;
  };
  return (
    <>
      <Modal
        centered
        show={showChangePickupModal}
        onHide={closeChangePickupModal}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Change Pickup Person</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate onSubmit={handleSubmit(onSubmitChangePickup)}>
            <Row className="g-3 mb-3">
              <Form.Group
                as={Col}
                className="mb-3"
                controlId="selectPcikupPerson"
              >
                <Form.Label>Select a New Pickup Person</Form.Label>
                <Form.Select
                  aria-label="Select a New Pickup Person"
                  isInvalid={!!errors.selectPcikupPerson}
                  {...register('selectPcikupPerson', {
                    required: 'Pickup Person is Required'
                  })}
                >
                  {pickupPersonnelData.map((employee, index) => (
                    <option key={employee.employeeId} value={index}>
                      {employee.name}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.selectPcikupPerson &&
                    errors.selectPcikupPerson.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      <Row className="g-3 mb-3">
        <Col xl={6}>
          <Card className="h-100">
            <Card.Body className="d-flex flex-column justify-content-between">
              <div className="d-flex gap-3 flex-column flex-sm-row align-items-center">
                <Image
                  fluid
                  width={150}
                  className="rounded-3"
                  src={student}
                  alt="student profile image"
                />
                <div className="d-flex flex-column">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="text-center">Authorized Pickup Person</h5>
                    <SoftBadge
                      bg="success"
                      pill
                      className="d-none d-md-inline-block ms-2"
                    >
                      Verified
                      <FontAwesomeIcon icon="check" className="ms-1" />
                    </SoftBadge>
                  </div>
                  <div>
                    <span className="fw-bold">Employee Id: </span>
                    <span>
                      {pickupPersonnelData[currentEmployee].employeeId}
                    </span>
                  </div>
                  <div>
                    <span className="fw-bold">Employee Name: </span>
                    <span>{pickupPersonnelData[currentEmployee].name}</span>
                  </div>
                  <div>
                    <span className="fw-bold">Email: </span>
                    <Link
                      to={`mailto:${pickupPersonnelData[currentEmployee].email}`}
                      className="text-600"
                    >
                      {pickupPersonnelData[currentEmployee].email}
                    </Link>
                  </div>
                  <div>
                    <span className="fw-bold">Mobile No: </span>
                    <Link
                      to={`tel:${pickupPersonnelData[currentEmployee].phoneNumber}`}
                      className="text-600"
                    >
                      {pickupPersonnelData[currentEmployee].phoneNumber}
                    </Link>
                  </div>
                </div>
              </div>
              {orgProfile === 'distributor'
                ? getDistributorChangePickupButton()
                : null}
            </Card.Body>
          </Card>
        </Col>
        <PickupCode atc={atc} />
      </Row>
    </>
  );
};

ProductPickupTabPane.propTypes = {
  atc: PropTypes.shape({
    id: PropTypes.number.isRequired,
    bank: PropTypes.object.isRequired,
    merchant: PropTypes.object.isRequired,
    distributor: PropTypes.object.isRequired,
    product: PropTypes.shape({
      productName: PropTypes.string.isRequired,
      productId: PropTypes.string.isRequired,
      quantityUnit: PropTypes.string.isRequired,
      logo: PropTypes.string.isRequired
    }).isRequired,
    totalQuantity: PropTypes.number.isRequired,
    issuedOn: PropTypes.string.isRequired,
    merchantApprovalWorkflow: PropTypes.array.isRequired,
    bankIssuingWorkflow: PropTypes.array.isRequired,
    status: PropTypes.number.isRequired,
    pickupCode: PropTypes.string
  })
};

export default ProductPickupTabPane;
