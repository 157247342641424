import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Row, Col, Button } from 'react-bootstrap';

import { atcStatus } from 'redux/slices/atcSlice';

const SummaryTabPane = ({ atc, setToApprovalTab }) => {
  const orgProfile = useSelector(state => state.config.orgProfile);

  const getDistributorAtcStatus = () => {
    if (atc.status === atcStatus.releasedToDistributor) {
      return (
        <div className="d-flex align-items-center">
          <FontAwesomeIcon icon="check-circle" className="text-success me-3" />
          Ready For Pickup
        </div>
      );
    } else if (atc.status === atcStatus.closed) {
      return (
        <div className="d-flex align-items-center">
          <FontAwesomeIcon
            icon="check-circle"
            className="text-secondary me-3"
          />
          Pickup Complete
        </div>
      );
    }
  };

  const getMerchantAtcStatus = () => {
    if (atc.status === atcStatus.created) {
      return (
        <div className="d-flex align-items-center">
          <FontAwesomeIcon icon="clock" className="text-secondary me-3" />
          Pending Internal Approval
        </div>
      );
    } else if (atc.status === atcStatus.approvedByMerchant) {
      return (
        <div className="d-flex align-items-center">
          <FontAwesomeIcon icon="clock" className="text-secondary me-3" />
          Pending Release to Bank
        </div>
      );
    } else if (
      atc.status === atcStatus.releasedToBank ||
      atc.status === atcStatus.approvedByBank
    ) {
      return (
        <div className="d-flex align-items-center">
          <FontAwesomeIcon icon="check-circle" className="text-success  me-3" />
          Released To Bank
        </div>
      );
    } else if (atc.status === atcStatus.releasedToDistributor) {
      return (
        <div className="d-flex align-items-center">
          <FontAwesomeIcon icon="check-circle" className="text-success me-3" />
          Released To Distributor
        </div>
      );
    } else if (atc.status === atcStatus.closed) {
      return (
        <div className="d-flex align-items-center">
          <FontAwesomeIcon
            icon="check-circle"
            className="text-secondary me-3"
          />
          Pickup Complete
        </div>
      );
    }
  };

  const getBankAtcStatus = () => {
    if (atc.status === atcStatus.releasedToBank) {
      return (
        <div className="d-flex align-items-center">
          <FontAwesomeIcon icon="clock" className="text-secondary me-3" />
          Received, Pending Internal Release Approval
        </div>
      );
    } else if (atc.status === atcStatus.approvedByBank) {
      return (
        <div className="d-flex align-items-center">
          <FontAwesomeIcon icon="clock" className="text-secondary me-3" />
          Approved, Pending Release to Distributor
        </div>
      );
    } else if (atc.status === atcStatus.releasedToDistributor) {
      return (
        <div className="d-flex align-items-center">
          <FontAwesomeIcon icon="check-circle" className="text-success me-3" />
          Released To Distributor
        </div>
      );
    } else if (atc.status === atcStatus.closed) {
      return (
        <div className="d-flex align-items-center">
          <FontAwesomeIcon
            icon="check-circle"
            className="text-secondary me-3"
          />
          Pickup Complete
        </div>
      );
    }
  };

  const getAtcStatus = () => {
    if (orgProfile === 'bank') {
      return getBankAtcStatus();
    } else if (orgProfile === 'merchant') {
      return getMerchantAtcStatus();
    } else if (orgProfile === 'distributor') {
      return getDistributorAtcStatus();
    }
  };

  return (
    <Row className="g-3 mb-3">
      <Col className="col-8">
        <Card>
          <Card.Header>
            <h5>Summary</h5>
            <hr className="mb-0" />
          </Card.Header>
          <Card.Body>
            <Row className="gx-auto mb-2">
              <Col>
                <span className="fw-bold">Issuing Merchant: </span>
                {atc.merchant.name}
              </Col>
              <Col>
                <span className="fw-bold">Product Name: </span>
                {atc.product.productName}
              </Col>
            </Row>
            <Row className="gx-auto mb-2">
              <Col>
                <span className="fw-bold">Bank: </span>
                {atc.bank.name}
              </Col>
              <Col>
                <span className="fw-bold">Assgined Distributor: </span>
                {atc.distributor.name}
              </Col>
            </Row>
            <Row className="gx-auto mb-2">
              <Col>
                <span className="fw-bold">Total Value:</span> ₦10,000,000
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
      <Col className="col-4">
        <Card>
          <Card.Header>
            <h5>ATC Status</h5>
            <hr className="mb-0" />
          </Card.Header>
          <Card.Body className="d-flex flex-column gap-3">
            <div className="d-flex align-items-center">{getAtcStatus()}</div>
            {orgProfile !== 'distributor' ? (
              <div className="d-flex align-items-center ms-auto">
                <Button variant="outline-primary" onClick={setToApprovalTab}>
                  See Approvers
                </Button>
              </div>
            ) : null}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

SummaryTabPane.propTypes = {
  atc: PropTypes.shape({
    id: PropTypes.number.isRequired,
    bank: PropTypes.object.isRequired,
    merchant: PropTypes.object.isRequired,
    distributor: PropTypes.object.isRequired,
    product: PropTypes.shape({
      productName: PropTypes.string.isRequired,
      productId: PropTypes.string.isRequired,
      quantityUnit: PropTypes.string.isRequired,
      logo: PropTypes.string.isRequired
    }).isRequired,
    totalQuantity: PropTypes.number.isRequired,
    issuedOn: PropTypes.string.isRequired,
    merchantApprovalWorkflow: PropTypes.array.isRequired,
    bankIssuingWorkflow: PropTypes.array.isRequired,
    status: PropTypes.number.isRequired,
    pickupCode: PropTypes.string
  }),
  setToApprovalTab: PropTypes.func.isRequired
};

export default SummaryTabPane;
