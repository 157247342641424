import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';

import { toast } from 'react-toastify';
import { Button, Form, Row, Col } from 'react-bootstrap';
import Divider from 'components/common/Divider';

const RegistrationPage = () => {
  // State
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    isAccepted: false
  });

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    toast.success(`Successfully registered as ${formData.name}`, {
      theme: 'colored'
    });
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <>
      <Flex alignItems="center" justifyContent="between">
        <h3>Register</h3>
        <p className="mb-0 fs--1">
          <span className="fw-semi-bold">Already User? </span>
          <Link to="/app/login">Login</Link>
        </p>
      </Flex>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>Name</Form.Label>
          <Form.Control
            placeholder=""
            value={formData.name}
            name="name"
            onChange={handleFieldChange}
            type="text"
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            placeholder=""
            value={formData.email}
            name="email"
            onChange={handleFieldChange}
            type="text"
          />
        </Form.Group>

        <Row className="g-2 mb-3">
          <Form.Group as={Col} sm={6}>
            <Form.Label>Password</Form.Label>
            <Form.Control
              placeholder=""
              value={formData.password}
              name="password"
              onChange={handleFieldChange}
              type="password"
            />
          </Form.Group>
          <Form.Group as={Col} sm={6}>
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              placeholder=""
              value={formData.confirmPassword}
              name="confirmPassword"
              onChange={handleFieldChange}
              type="password"
            />
          </Form.Group>
        </Row>

        <Form.Group className="mb-3">
          <Form.Check
            type="checkbox"
            id="acceptCheckbox"
            className="form-check"
          >
            <Form.Check.Input
              type="checkbox"
              name="isAccepted"
              checked={formData.isAccepted}
              onChange={e =>
                setFormData({
                  ...formData,
                  isAccepted: e.target.checked
                })
              }
            />
            <Form.Check.Label className="form-label">
              I accept the <Link to="#!">terms</Link> and{' '}
              <Link to="#!">privacy policy</Link>
            </Form.Check.Label>
          </Form.Check>
        </Form.Group>

        <Form.Group className="mb-4">
          <Button
            className="w-100"
            type="submit"
            disabled={
              !formData.name ||
              !formData.email ||
              !formData.password ||
              !formData.confirmPassword ||
              !formData.isAccepted
            }
          >
            Register
          </Button>
        </Form.Group>
        <Divider>or register with</Divider>
      </Form>
    </>
  );
};

export default RegistrationPage;
