import React from 'react';
import { Row, Col } from 'react-bootstrap';

import PageGreeting from 'components/common/PageGreeting';

const vaultQuickLinks = [
  {
    title: 'Reports',
    text: 'Analyze with a few clicks',
    icon: 'chart-line',
    color: 'primary'
  },
  {
    title: 'How does the Product Vault Work?',
    text: 'Learn how digital product stock makes it to your vault! ',
    icon: 'question',
    color: 'warning'
  }
];

const DistributorsHomePage = () => {
  return (
    <>
      <Row className="g-3 mb-3">
        <Col>
          <PageGreeting
            title="Distributors Home"
            subtitle="Here are some links to get you started,"
            quickLinks={vaultQuickLinks}
          />
        </Col>
      </Row>
      <Row className="g-3 mb-3"></Row>
    </>
  );
};

export default DistributorsHomePage;
