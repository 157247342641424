export const branches = [
  {
    branchName: 'Port Harcourt Branch',
    memberCount: 4
  },
  {
    branchName: 'Abuja Branch',
    memberCount: 5
  },
  {
    branchName: 'Lagos Branch',
    memberCount: 15
  },
  {
    branchName: 'Kaduna Branch',
    memberCount: 2
  }
];
