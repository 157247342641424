import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Form, Button, Card } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { useSelector, useDispatch } from 'react-redux';
import { createAtc } from 'redux/slices/atcSlice';
import SubPageLayout from 'layouts/SubPageLayout';

import { banks, distributors, merchants } from 'data/supply-chain/common';

const CreateAtcPage = () => {
  const { orgId } = useSelector(state => state.config);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors }
  } = useForm();

  const onSubmit = data => {
    const newAtcs = [];

    for (let idx = 0; idx < data.atcQuantity; idx++) {
      newAtcs.push({
        bank: banks[data.selectBank],
        merchant: merchants[orgId],
        distributor: distributors[data.selectDistributor],
        product: merchants[orgId].products[data.selectProduct],
        totalQuantity: data.productQuantity,
        usage: 0
      });
    }

    dispatch(createAtc(newAtcs));
    toast('ATC Creation Succeeded !');
    reset();
  };

  const watchSelectBank = watch('selectBank', '');
  const watchSelectDistributor = watch('selectDistributor', '');
  const watchSelectProduct = watch('selectProduct', '');
  const watchAtcQuantity = watch('atcQuantity', 0);
  const watchProductQuantity = watch('productQuantity', 0);

  return (
    <>
      <SubPageLayout
        back="/app/supplychain/atc"
        buttons={[]}
        title="Create New ATC"
      >
        <Card>
          <Card.Header>
            <h5>Generate New ATCs</h5>
          </Card.Header>
          <Card.Body>
            <Row className="g-3 mb-3">
              <Col>
                <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                  <Row className="g-3 mb-3">
                    <Form.Group
                      as={Col}
                      className="mb-3"
                      controlId="selectBank"
                    >
                      <Form.Label>Select a Bank</Form.Label>
                      <Form.Select
                        aria-label="Select a Bank"
                        isInvalid={!!errors.selectBank}
                        {...register('selectBank', {
                          required: 'Bank field is required'
                        })}
                      >
                        <option> </option>
                        {Object.values(banks).map(bank => {
                          return (
                            <option
                              key={`create-atc-bank-option-${bank.id}`}
                              value={bank.id}
                            >
                              {bank.name}
                            </option>
                          );
                        })}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors.selectBank && errors.selectBank.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      className="mb-3"
                      controlId="selectDistributor"
                    >
                      <Form.Label>Select a Distributor</Form.Label>
                      <Form.Select
                        aria-label="Select a Distributor"
                        isInvalid={!!errors.selectDistributor}
                        {...register('selectDistributor', {
                          required: 'Distributor field is required'
                        })}
                      >
                        <option> </option>
                        {Object.values(distributors).map(distributor => {
                          return (
                            <option
                              key={`create-atc-distributor-option-${distributor.id}`}
                              value={distributor.id}
                            >
                              {distributor.name}
                            </option>
                          );
                        })}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors.selectDistributor &&
                          errors.selectDistributor.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <Row className="mb-3 g-3">
                    <Form.Group
                      as={Col}
                      className="mb-3"
                      controlId="selectProduct"
                    >
                      <Form.Label>Select a Product</Form.Label>
                      <Form.Select
                        aria-label="Select a Product"
                        isInvalid={!!errors.selectProduct}
                        {...register('selectProduct', {
                          required: 'Product field is required'
                        })}
                      >
                        <option> </option>
                        {Object.values(merchants[orgId].products).map(
                          product => {
                            return (
                              <option
                                key={`create-atc-product-option-${product.id}`}
                                value={product.id}
                              >
                                {product.productName}
                              </option>
                            );
                          }
                        )}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors.selectProduct && errors.selectProduct.message}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} controlId="productQuantity">
                      <Form.Label>Product Quantity per ATC</Form.Label>
                      <Form.Control
                        type="number"
                        name="productQuantity"
                        isInvalid={!!errors.productQuantity}
                        {...register('productQuantity', {
                          required: 'Product Quantity field is required'
                        })}
                      />

                      <Form.Control.Feedback type="invalid">
                        {errors.productQuantity &&
                          errors.productQuantity.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      controlId="productQuantityUnit"
                      className="col-2"
                    >
                      <Form.Label>Unit</Form.Label>
                      <Form.Control
                        name="productQuantityUnit"
                        value="Bags"
                        disabled
                      />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3 g-3">
                    <Form.Group
                      as={Col}
                      controlId="atcQuantity"
                      className="col-4"
                    >
                      <Form.Label>Number of ATCs to Generate</Form.Label>
                      <Form.Control
                        type="number"
                        name="atcQuantity"
                        isInvalid={!!errors.atcQuantity}
                        {...register('atcQuantity', {
                          required: 'ATC Quantity field is required'
                        })}
                      />

                      <Form.Control.Feedback type="invalid">
                        {errors.atcQuantity && errors.atcQuantity.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <Button variant="primary" type="submit">
                    Submit
                  </Button>
                </Form>
              </Col>
              <Col className="col-4 mx-2 bg-100 p-2 border rounded-1">
                <Row>
                  <h5>Submission Preview</h5>
                </Row>
                <hr className="mx-0 my-2 text-400" />
                <Row className="mb-2">
                  <div>
                    <span className="fw-bold">Bank: </span>
                    <span>
                      {watchSelectBank
                        ? banks[watchSelectBank].name
                        : 'None Selected'}
                    </span>
                  </div>
                </Row>
                <Row className="mb-2">
                  <div>
                    <span className="fw-bold">Distributor: </span>
                    <span>
                      {watchSelectDistributor
                        ? distributors[watchSelectDistributor].name
                        : 'None Selected'}
                    </span>
                  </div>
                </Row>
                <Row className="mb-2">
                  <div>
                    <span className="fw-bold">Product: </span>
                    <span>
                      {watchSelectProduct
                        ? merchants[orgId].products[watchSelectProduct]
                            .productName
                        : 'None Selected'}
                    </span>
                  </div>
                </Row>
                <Row className="mb-2">
                  <div>
                    <span className="fw-bold">Number of ATCs: </span>
                    <span>{watchAtcQuantity}</span>
                  </div>
                </Row>
                <Row className="mb-2">
                  <div>
                    <span className="fw-bold">Total Value of ATCs: </span>
                    <span>
                      ₦{' '}
                      {(
                        watchProductQuantity *
                        watchAtcQuantity *
                        4000
                      ).toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </span>
                  </div>
                </Row>
              </Col>
            </Row>
            <Row className="g-3 mb-3"></Row>
          </Card.Body>
        </Card>
      </SubPageLayout>
    </>
  );
};

export default CreateAtcPage;
