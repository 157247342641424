import React, { useState, useEffect } from 'react';
import { Card, Col, Form, Row, Image } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { setAtcSearchState, atcStatus } from 'redux/slices/atcSlice';
import PropTypes from 'prop-types';

import IconButton from 'components/common/IconButton';
import { merchants, distributors } from 'data/supply-chain/common';

import AtcItem from '../common/atcItem';
import AtcSearchEntityItem from '../common/atcSearchEntityItem';

const BankAtcSearch = ({ bankId }) => {
  const { atcs, lastAtcSearchState } = useSelector(state => state.atc);
  const [currentView, setCurrentView] = useState('merchantsView');
  const [currentMerchantId, setCurrentMerchantId] = useState(null);
  const [currentDistributorId, setCurrentDistributorId] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    /** Here we reload the current search view based on the
     * search state saved in the redux store.
     */

    if (lastAtcSearchState.distributorId) {
      setCurrentMerchantId(lastAtcSearchState.merchantId);
      setCurrentDistributorId(lastAtcSearchState.distributorId);
      setCurrentView('atcsView');
    } else if (lastAtcSearchState.merchantId) {
      setCurrentMerchantId(lastAtcSearchState.merchantId);
      setCurrentView('distributorsView');
    }
  }, []);

  const getViewableAtcs = allAtcs => {
    let viewableAtcs = [];

    Object.values(allAtcs).forEach(atc => {
      if (atc.bank.id === bankId && atc.status >= atcStatus.releasedToBank) {
        viewableAtcs.push(atc);
      }
    });

    return viewableAtcs;
  };

  const showBackButton = () => {
    return currentView === 'atcsView' || currentView === 'distributorsView';
  };

  const onClickBackButton = () => {
    if (currentView === 'distributorsView') {
      dispatch(
        setAtcSearchState({
          merchantId: null
        })
      );
      setCurrentView('merchantsView');
    } else if (currentView === 'atcsView') {
      dispatch(
        setAtcSearchState({
          distributorId: null
        })
      );
      setCurrentView('distributorsView');
    }
  };

  const handleOnClickMerchant = merchantId => {
    setCurrentMerchantId(merchantId);
    setCurrentView('distributorsView');
    dispatch(
      setAtcSearchState({
        merchantId: merchantId
      })
    );
  };

  const handleOnClickDistributor = distributorId => {
    setCurrentView('atcsView');
    setCurrentDistributorId(distributorId);
    dispatch(
      setAtcSearchState({
        distributorId: distributorId
      })
    );
  };

  const getMerchantsView = () => {
    // Find the Merchants product vault data
    const merchantsData = [];
    const seenMerchantIds = new Set();
    for (const item of getViewableAtcs(atcs)) {
      if (!seenMerchantIds.has(item.merchant.id)) {
        merchantsData.push(item.merchant);
        seenMerchantIds.add(item.merchant.id);
      }
    }

    return (
      <Row className="text-center justify-content-start">
        {merchantsData.map(item => {
          return (
            <Col
              key={`merchant-entity-${item.id}`}
              xs={6}
              md={4}
              lg={3}
              xxl={2}
              className="m-2"
            >
              <AtcSearchEntityItem
                entity={item}
                handleOnClick={handleOnClickMerchant}
              />
            </Col>
          );
        })}
      </Row>
    );
  };

  const getDistributorsView = () => {
    // Find the Merchants product vault data
    const distributorsData = [];
    const seenDistributorIds = new Set();
    for (const item of getViewableAtcs(atcs)) {
      if (
        item.merchant.id === currentMerchantId &&
        !seenDistributorIds.has(item.distributor.id)
      ) {
        distributorsData.push(item.distributor);
        seenDistributorIds.add(item.distributor.id);
      }
    }

    return (
      <Row className="text-center justify-content-start">
        {distributorsData.map(item => {
          return (
            <Col
              key={`distributor-entity-${item.id}`}
              xs={6}
              md={4}
              lg={3}
              xxl={2}
              className="m-2"
            >
              <AtcSearchEntityItem
                entity={item}
                handleOnClick={handleOnClickDistributor}
              />
            </Col>
          );
        })}
      </Row>
    );
  };

  const getAtcItemsView = () => {
    // Find the ATCs issued to a specifc bank and distributor
    let atcData = [];
    for (const item of getViewableAtcs(atcs)) {
      if (
        item.merchant.id === currentMerchantId &&
        item.distributor.id === currentDistributorId
      ) {
        atcData.push(item);
      }
    }

    return (
      <Row className="text-center g-4 px-2">
        {atcData.map(item => (
          <Col key={`atc-item-${item.id}`} xs={12} md={6} lg={6} xxl={4}>
            <AtcItem item={item} shouldLink={false} />
          </Col>
        ))}
      </Row>
    );
  };

  const getView = view => {
    if (view === 'merchantsView') {
      return getMerchantsView();
    } else if (view === 'distributorsView') {
      return getDistributorsView();
    } else if (view === 'atcsView') {
      return getAtcItemsView();
    }
    return null;
  };

  const getCurrentMerchantTag = () => {
    if (
      currentMerchantId &&
      (currentView === 'distributorsView' || currentView === 'atcsView')
    ) {
      let currentMerchant = null;
      for (const item of Object.values(merchants)) {
        if (item.id === currentMerchantId) {
          currentMerchant = item;
        }
      }

      if (!currentMerchant) {
        return null;
      }

      return (
        <div className="card ms-3">
          <div className="d-flex">
            <div className="me-auto">
              <Image
                fluid
                style={{ height: '2rem' }}
                src={currentMerchant.logo}
                className="p-1"
              />
            </div>
            <div className="ms-auto d-flex align-items-center">
              <div className="card-body d-flex p-0">
                <p className="card-text mx-1">{currentMerchant.name}</p>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  const getCurrentDistributorTag = () => {
    if (currentDistributorId && currentView === 'atcsView') {
      let currentDistributor = null;
      for (const item of Object.values(distributors)) {
        if (item.id === currentDistributorId) {
          currentDistributor = item;
        }
      }

      if (!currentDistributor) {
        return null;
      }

      return (
        <div className="card ms-3">
          <div className="d-flex">
            <div className="me-auto rounded-3">
              <Image
                fluid
                style={{ height: '2rem' }}
                src={currentDistributor.logo}
                className="p-1"
              />
            </div>
            <div className="ms-auto d-flex align-items-center">
              <div className="card-body d-flex p-0">
                <p className="card-text mx-1">{currentDistributor.name}</p>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <Card>
      <Card.Header className="bg-light">
        <div className="d-flex align-items-center g-1">
          <div className="d-flex align-items-center">
            <IconButton
              onClick={onClickBackButton}
              variant="falcon-default"
              size="sm"
              icon="arrow-left"
              className={`me-3 ${showBackButton() ? null : 'd-none'}`}
            />
            <h5 className="mb-0">Search All ATCs </h5>
          </div>
          <div className="ms-auto me-2 d-flex justify-content-end align-items-center">
            <div className="flex-grow-1" style={{ width: '30rem' }}>
              <Form.Control placeholder="Search..." size="sm" />
            </div>
            {getCurrentMerchantTag()}
            {getCurrentDistributorTag()}
          </div>
        </div>
      </Card.Header>
      <Card.Body className="bg-light px-1 pb-1 pt-0 fs--1">
        <hr className="mt-0 mx-2" />
        {getView(currentView)}
        <hr className="mx-2" />
      </Card.Body>
    </Card>
  );
};

BankAtcSearch.propTypes = {
  bankId: PropTypes.number.isRequired
};

export default BankAtcSearch;
