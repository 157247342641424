import React from 'react';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';

const TeamsPreviewItem = ({ teamName, memberCount }) => (
  <Flex alignItems="center" className="position-relative mb-2">
    <Avatar size="xl" name={teamName} className="me-2" />
    <div>
      <h6 className="fs-0 mb-0">
        <a className="stretched-link" href="#">
          {teamName} Team
        </a>
      </h6>
      <p className="mb-1">{memberCount} members</p>
    </div>
  </Flex>
);

TeamsPreviewItem.propTypes = {
  teamName: PropTypes.string.isRequired,
  memberCount: PropTypes.number.isRequired
};

export default TeamsPreviewItem;
