import PropTypes from 'prop-types';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';

import TeamsPreviewItem from './TeamsPreviewItem';

const TeamsPreview = ({
  teams,
  colBreakpoints = { sm: 6, md: 4 },
  ...rest
}) => {
  return (
    <Card {...rest}>
      <Card.Header className="bg-light">
        <h5 className="mb-0">Teams</h5>
      </Card.Header>
      <Card.Body className="fs--1">
        <Row className="g-3">
          {teams.map(team => (
            <Col key={team.teamName} {...colBreakpoints}>
              <TeamsPreviewItem
                teamName={team.teamName}
                memberCount={team.memberCount}
              />
            </Col>
          ))}
        </Row>
      </Card.Body>
    </Card>
  );
};

TeamsPreview.propTypes = {
  teams: PropTypes.arrayOf(PropTypes.shape(TeamsPreviewItem.propTypes)),
  colBreakpoints: PropTypes.object
};

export default TeamsPreview;
