import React from 'react';
import PropTypes from 'prop-types';

import FalconCardHeader from 'components/common/FalconCardHeader';
import { Card } from 'react-bootstrap';
import FalconCardFooterLink from 'components/common/FalconCardFooterLink';
import TodoPreviewItem from './TodoPreviewItem';

const TodoPreview = ({ todos, ...rest }) => {
  return (
    <Card {...rest}>
      <FalconCardHeader title="Upcoming Todos" light />
      <Card.Body className="fs--1 border-bottom">
        {todos.map((todo, index) => (
          <TodoPreviewItem
            key={todo.id}
            details={todo}
            isLast={index === todos.length - 1}
          />
        ))}
      </Card.Body>
      <FalconCardFooterLink title="All Todos" to="#" size="sm" />
    </Card>
  );
};

TodoPreview.propTypes = {
  todos: PropTypes.arrayOf(PropTypes.shape(Event.propTypes))
};

export default TodoPreview;
