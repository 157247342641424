import React from 'react';
import PropTypes from 'prop-types';
import { useQRCode } from 'next-qrcode';
import { useForm } from 'react-hook-form';

import { useSelector, useDispatch } from 'react-redux';
import { refreshPickupCode, atcStatus } from 'redux/slices/atcSlice';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Col, Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';

const PickupCode = ({ atc }) => {
  const { Canvas } = useQRCode();
  const orgProfile = useSelector(state => state.config.orgProfile);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const onSubmitValidatePickup = data => {
    if (atc.pickupCode === data.enterPickupCode) {
      toast('Pickup Code Validated Successful!', { type: 'success' });
    } else {
      toast('Pickup Code Validated Failed!', { type: 'error' });
    }
  };

  const getMerchantPickupCodeValidator = () => {
    if (atc.status === atcStatus.releasedToDistributor) {
      return (
        <Col xl={6}>
          <Card>
            <Card.Body>
              <div className="d-flex flex-column">
                <div className="text-center d-flex flex-column">
                  <h4>Validate Pickup Code</h4>
                  <hr className="mt-0" />
                </div>
                <Form
                  noValidate
                  onSubmit={handleSubmit(onSubmitValidatePickup)}
                >
                  <div className="d-flex flex-column">
                    <Form.Group
                      as={Col}
                      className="mb-3"
                      controlId="enterPickupCode"
                    >
                      <Form.Label>Enter Code</Form.Label>
                      <Form.Control
                        type="text"
                        name="enterPickupCode"
                        isInvalid={!!errors.enterPickupCode}
                        {...register('enterPickupCode', {
                          required: 'Pickup Code field is required'
                        })}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.enterPickupCode &&
                          errors.enterPickupCode.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Button variant="primary" type="submit" className="ms-auto">
                      Validate
                    </Button>
                  </div>
                </Form>
              </div>
            </Card.Body>
          </Card>
        </Col>
      );
    } else if (atc.status === atcStatus.closed) {
      return (
        <Col xl={6}>
          <Card>
            <Card.Body>
              <div className="d-flex flex-column">
                <div className="text-center d-flex flex-column">
                  <h4>Validate Pickup Code</h4>
                  <hr className="mt-0" />
                </div>
                <div className="text-center">
                  <FontAwesomeIcon
                    icon="check-circle"
                    className="text-success me-2"
                  />
                  Pickup confirmed
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      );
    }

    return null;
  };

  const getDistributorPickupCodeDisplay = () => {
    if (atc.status === atcStatus.releasedToDistributor) {
      return (
        <Col xl={6}>
          <Card>
            <Card.Body>
              <div className="d-flex">
                <div className="d-flex flex-column align-items-center">
                  <div className="text-center mt-auto ">
                    <h4>Pickup Code: {atc.pickupCode}</h4>
                  </div>
                  <Button
                    variant="outline-secondary mt-auto"
                    onClick={() => {
                      dispatch(refreshPickupCode({ atcId: atc.id }));
                    }}
                  >
                    <FontAwesomeIcon icon="sync-alt" size="sm" /> Refresh
                  </Button>
                </div>
                <div className="vr mx-2" />
                <Canvas
                  text={atc.pickupCode}
                  options={{
                    level: 'M',
                    margin: 3,
                    scale: 4,
                    width: 200,
                    color: {
                      dark: '#000000',
                      light: '#FFFFFF'
                    }
                  }}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      );
    } else if (atc.status === atcStatus.closed) {
      return (
        <Col xl={6}>
          <Card>
            <Card.Body>
              <div className="d-flex flex-column">
                <div className="text-center d-flex flex-column">
                  <h4>Pickup Code</h4>
                  <hr className="mt-0" />
                </div>
                <div className="text-center">
                  <FontAwesomeIcon
                    icon="check-circle"
                    className="text-success me-2"
                  />
                  Pickup confirmed
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      );
    }
  };
  return (
    <>
      {orgProfile === 'distributor' ? getDistributorPickupCodeDisplay() : null}
      {orgProfile === 'merchant' ? getMerchantPickupCodeValidator() : null}
    </>
  );
};

PickupCode.propTypes = {
  atc: PropTypes.shape({
    id: PropTypes.number.isRequired,
    bank: PropTypes.object.isRequired,
    merchant: PropTypes.object.isRequired,
    distributor: PropTypes.object.isRequired,
    product: PropTypes.shape({
      productName: PropTypes.string.isRequired,
      productId: PropTypes.string.isRequired,
      quantityUnit: PropTypes.string.isRequired,
      logo: PropTypes.string.isRequired
    }).isRequired,
    totalQuantity: PropTypes.number.isRequired,
    issuedOn: PropTypes.string.isRequired,
    merchantApprovalWorkflow: PropTypes.array.isRequired,
    bankIssuingWorkflow: PropTypes.array.isRequired,
    status: PropTypes.number.isRequired,
    pickupCode: PropTypes.string
  })
};

export default PickupCode;
