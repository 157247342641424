import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { banks, merchants, distributors } from 'data/supply-chain/common';

const atcStatus = {
  created: 0,
  approvedByMerchant: 1,
  releasedToBank: 2,
  approvedByBank: 3,
  releasedToDistributor: 4,
  closed: 5
};

const initialState = {
  lastAddedAtc: 1,
  atcs: {
    1: {
      id: 1,
      bank: banks[1],
      merchant: merchants[1],
      distributor: distributors[1],
      product: merchants[1].products[1],
      totalQuantity: 1000,
      issuedOn: 'Wednesday, March 23, 2022',
      usage: 20,
      merchantApprovalWorkflow: [
        {
          stepOrder: 1,
          stepOutcome: null,
          stepTitle: 'Review Distributor Account',
          stepSubTitle:
            'Make sure distributor account is current and still approved',
          stepDescription:
            'Make sure distributor account is current and still approved',
          stepOwner: 'Bola',
          actionedOn: '10:28 AM, March 23, 2023'
        },
        {
          stepOrder: 2,
          stepOutcome: null,
          stepTitle: 'Review Bank Details',
          stepSubTitle:
            'Ensure Bank has completed payment to designated account',
          stepDescription:
            'Ensure Bank has completed payment to designated account',
          stepOwner: 'Bola',
          actionedOn: '11:30 AM, March 25, 2023'
        },
        {
          stepOrder: 3,
          stepOutcome: null,
          stepTitle: 'Final ATC Review',
          stepSubTitle:
            'Review ATC quantity and product details to ensure accuracy',
          stepDescription:
            'Review ATC quantity and product details to ensure accuracy',
          stepOwner: 'Oluchi',
          actionedOn: '9:17 AM, March 28, 2023'
        }
      ],
      bankIssuingWorkflow: [
        {
          stepOrder: 1,
          stepOutcome: null,
          stepTitle: 'Review Received ATC',
          stepSubTitle: 'Ensure received ATC matches up with purchase order',
          stepDescription: 'Ensure received ATC matches up with purchase order',
          stepOwner: 'Kemi',
          actionedOn: '10:28 AM, Apr 2, 2023'
        },
        {
          stepOrder: 2,
          stepOutcome: null,
          stepTitle: 'Confirm Distributor Payment',
          stepSubTitle:
            'Confirm distributor has completed payment for entire value of ATC',
          stepDescription:
            'Confirm distributor has completed payment for entire value of ATC',
          stepOwner: 'Kalu',
          actionedOn: '1:15 PM, Apr 4, 2023'
        }
      ],
      status: atcStatus.created,
      pickupCode: null
    }
  },
  lastAtcSearchState: {
    bankId: null,
    merchantId: null,
    distributorId: null
  }
};

export const atcSlice = createSlice({
  name: 'atc',
  initialState,
  reducers: {
    createAtc: (state, action) => {
      const newAtcs = action.payload;
      let lastAtcId = state.lastAddedAtc;

      // Get current date in correct format
      const currentDate = new Date(Date.now());
      const currentDateString = currentDate.toLocaleDateString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });

      newAtcs.forEach(newAtc => {
        state.atcs[lastAtcId + 1] = {
          id: lastAtcId + 1,
          issuedOn: currentDateString,
          ...newAtc,
          merchantApprovalWorkflow: [
            {
              stepOrder: 1,
              stepOutcome: null,
              stepTitle: 'Review Distributor Account',
              stepSubTitle:
                'Make sure distributor account is current and still approved',
              stepDescription:
                'Make sure distributor account is current and still approved',
              stepOwner: 'Bola',
              actionedOn: '10:28 AM, March 23, 2023'
            },
            {
              stepOrder: 2,
              stepOutcome: null,
              stepTitle: 'Review Bank Details',
              stepSubTitle:
                'Ensure Bank has completed payment to designated account',
              stepDescription:
                'Ensure Bank has completed payment to designated account',
              stepOwner: 'Bola',
              actionedOn: '11:30 AM, March 25, 2023'
            },
            {
              stepOrder: 3,
              stepOutcome: null,
              stepTitle: 'Final ATC Review',
              stepSubTitle:
                'Review ATC quantity and product details to ensure accuracy',
              stepDescription:
                'Review ATC quantity and product details to ensure accuracy',
              stepOwner: 'Oluchi',
              actionedOn: '9:17 AM, March 28, 2023'
            }
          ],
          bankIssuingWorkflow: [
            {
              stepOrder: 1,
              stepOutcome: null,
              stepTitle: 'Review Received ATC',
              stepSubTitle:
                'Ensure received ATC matches up with purchase order',
              stepDescription:
                'Ensure received ATC matches up with purchase order',
              stepOwner: 'Kemi',
              actionedOn: '10:28 AM, Apr 2, 2023'
            },
            {
              stepOrder: 2,
              stepOutcome: null,
              stepTitle: 'Confirm Distributor Payment',
              stepSubTitle:
                'Confirm distributor has completed payment for entire value of ATC',
              stepDescription:
                'Confirm distributor has completed payment for entire value of ATC',
              stepOwner: 'Kalu',
              actionedOn: '1:15 PM, Apr 4, 2023'
            }
          ],
          status: atcStatus.created
        };
        lastAtcId += 1;
      });

      state.lastAddedAtc = lastAtcId;
    },
    setAtcSearchState: (state, action) => {
      Object.keys(action.payload).forEach(element => {
        state.lastAtcSearchState[element] = action.payload[element];
      });
    },
    clearAtcSearchState: state => {
      state.lastAtcSearchState = initialState.lastAtcSearchState;
    },
    updateMerchantApprovalWorkflow: (state, action) => {
      const { atcId, newStep } = action.payload;
      let currentStep =
        state.atcs[atcId].merchantApprovalWorkflow[newStep.stepOrder - 1];

      Object.keys(newStep).forEach(element => {
        currentStep[element] = newStep[element];
      });

      // If this is the final step the update atc status
      if (
        newStep.stepOrder ===
          state.atcs[atcId].merchantApprovalWorkflow.length &&
        newStep.stepOutcome === true
      ) {
        state.atcs[atcId].status = atcStatus.approvedByMerchant;
      }
    },
    updateBankIssuingWorkflow: (state, action) => {
      const { atcId, newStep } = action.payload;
      let currentStep =
        state.atcs[atcId].bankIssuingWorkflow[newStep.stepOrder - 1];

      Object.keys(newStep).forEach(element => {
        currentStep[element] = newStep[element];
      });

      // If this is the final step the update atc status
      if (
        newStep.stepOrder === state.atcs[atcId].bankIssuingWorkflow.length &&
        newStep.stepOutcome === true
      ) {
        state.atcs[atcId].status = atcStatus.approvedByBank;
      }
    },
    setAtcStatus: (state, action) => {
      const { atcId, newStatus } = action.payload;

      state.atcs[atcId].status = newStatus;
    },
    refreshPickupCode: (state, action) => {
      const { atcId } = action.payload;
      const newPickupCode = _.toUpper(
        Math.random().toString(36).substring(2, 10)
      );
      state.atcs[atcId].pickupCode = newPickupCode;
    }
  }
});

export const {
  createAtc,
  setAtcSearchState,
  clearAtcSearchState,
  updateMerchantApprovalWorkflow,
  updateBankIssuingWorkflow,
  setAtcStatus,
  refreshPickupCode
} = atcSlice.actions;
export { atcStatus };

export default atcSlice;
