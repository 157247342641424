import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';

import SoftBadge from 'components/common/SoftBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Col,
  Row,
  Modal,
  Button,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import { toast } from 'react-toastify';

const ApprovalWorkflowTabPane = ({ workflow, updateWorkflowCallback }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [showStepModal, setShowStepModal] = useState(false);

  const openStepModal = stepId => {
    // If previous is no approved, user cannot action this step
    if (stepId > 1) {
      if (workflow[stepId - 2].stepOutcome !== true) {
        toast('Previous Step Must Be Approved!', { type: 'error' });
        return;
      }
    }
    setCurrentStep(stepId);
    setShowStepModal(true);
  };

  const closeStepModal = () => {
    setShowStepModal(false);
  };

  const getStepOutcomeText = stepOutcome => {
    if (stepOutcome === true) {
      return 'Approved';
    } else if (stepOutcome === false) {
      return 'Rejected';
    }

    return 'Pending';
  };

  const getStepOutcomeBg = stepOutcome => {
    if (stepOutcome === true) {
      return 'success';
    } else if (stepOutcome === false) {
      return 'danger';
    }

    return 'warning';
  };

  const actionStep = outcome => {
    let newStep = _.cloneDeep(workflow[currentStep - 1]);
    newStep.stepOutcome = outcome;

    updateWorkflowCallback(newStep);
    closeStepModal();
  };

  return (
    <>
      <Modal
        centered
        show={showStepModal}
        onHide={closeStepModal}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{workflow[currentStep - 1]?.stepTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="gx-auto mb-2">
            <div>{workflow[currentStep - 1]?.stepDescription}</div>
          </Row>
          <hr />
          <Row>
            <div className="d-flex justify-content-between px-3">
              <Button
                variant="success"
                onClick={() => {
                  actionStep(true);
                }}
                disabled={workflow[currentStep - 1].stepOutcome !== null}
              >
                Approve Step
              </Button>
              <Button
                variant="danger"
                onClick={() => {
                  actionStep(false);
                }}
                disabled={workflow[currentStep - 1].stepOutcome !== null}
              >
                Reject Step
              </Button>
            </div>
          </Row>
        </Modal.Body>
      </Modal>
      <div className="timeline-vertical py-0">
        {workflow.map((item, index) => {
          const {
            actionedOn,
            stepOutcome,
            stepTitle,
            stepSubTitle,
            stepOwner,
            stepOrder
          } = item;
          return (
            <div
              key={index}
              className={classNames('timeline-item', {
                'timeline-item-start': index % 2 === 0,
                'timeline-item-end': index % 2 !== 0
              })}
            >
              <OverlayTrigger
                key={`overlay-trigger-${index}`}
                placement="top"
                overlay={
                  <Tooltip
                    style={{ position: 'fixed' }}
                    id={`workflow-step-tooltip-${index}`}
                  >
                    Click To See Step Details
                  </Tooltip>
                }
              >
                <div
                  className="timeline-icon icon-item icon-item-lg text-secondary hover-primary border-300"
                  style={{ cursor: 'pointer' }}
                  onClick={() => openStepModal(stepOrder)}
                >
                  <FontAwesomeIcon icon="stamp" className="fs-1" />
                </div>
              </OverlayTrigger>
              <Row
                className={` ${
                  index % 2 == 0 ? 'timeline-item-start' : 'timeline-item-end'
                }`}
              >
                <Col lg={6} className="timeline-item-time">
                  <div>
                    <h6 className="mb-0 text-700">Step Owner</h6>
                    <p className="fs--2 text-500 font-sans-serif">
                      {stepOwner}
                    </p>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="timeline-item-content arrow-bg-white">
                    <div className="timeline-item-card bg-white dark__bg-1100">
                      <h5 className="mb-2 text-primary">{stepTitle}</h5>
                      <p className="border-bottom mb-3 pb-4 text-600 fs--1">
                        {stepSubTitle}
                      </p>
                      <div
                        wrap="wrap"
                        className="d-flex justify-content-between"
                      >
                        {stepOutcome ? (
                          <h6 className="mb-0 text-600 lh-base">
                            <FontAwesomeIcon
                              icon={['far', 'clock']}
                              className="me-1"
                            />
                            {actionedOn}
                          </h6>
                        ) : null}
                        <SoftBadge
                          bg={getStepOutcomeBg(stepOutcome)}
                          className="ms-auto"
                        >
                          {getStepOutcomeText(stepOutcome)}
                        </SoftBadge>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          );
        })}
      </div>
    </>
  );
};

ApprovalWorkflowTabPane.propTypes = {
  workflow: PropTypes.arrayOf(
    PropTypes.shape({
      stepOrder: PropTypes.number.isRequired,
      stepTitle: PropTypes.string.isRequired,
      stepSubTitle: PropTypes.string.isRequired,
      stepDescription: PropTypes.string.isRequired,
      stepOwner: PropTypes.string.isRequired,
      actionedOn: PropTypes.string,
      stepOutcome: PropTypes.bool
    })
  ),
  updateWorkflowCallback: PropTypes.func.isRequired
};

export default ApprovalWorkflowTabPane;
