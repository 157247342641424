import React, { useState, useEffect } from 'react';
import { Card, Col, Form, Row, Image } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { setAtcSearchState } from 'redux/slices/atcSlice';
import PropTypes from 'prop-types';

import IconButton from 'components/common/IconButton';
import { banks, distributors } from 'data/supply-chain/common';

import AtcItem from '../common/atcItem';
import AtcSearchEntityItem from '../common/atcSearchEntityItem';

const MerchantAtcSearch = ({ merchantId }) => {
  const { atcs, lastAtcSearchState } = useSelector(state => state.atc);
  const [currentView, setCurrentView] = useState('banksView');
  const [currentBankId, setCurrentBankId] = useState(null);
  const [currentDistributorId, setCurrentDistributorId] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    /** Here we reload the current search view based on the
     * search state saved in the redux store.
     */

    if (lastAtcSearchState.distributorId) {
      setCurrentBankId(lastAtcSearchState.bankId);
      setCurrentDistributorId(lastAtcSearchState.distributorId);
      setCurrentView('atcsView');
    } else if (lastAtcSearchState.bankId) {
      setCurrentBankId(lastAtcSearchState.bankId);
      setCurrentView('distributorsView');
    }
  }, []);

  const getViewableAtcs = allAtcs => {
    let viewableAtcs = [];

    Object.values(allAtcs).forEach(atc => {
      if (atc.merchant.id === merchantId) {
        viewableAtcs.push(atc);
      }
    });

    return viewableAtcs;
  };

  const showBackButton = () => {
    return currentView === 'atcsView' || currentView === 'distributorsView';
  };

  const onClickBackButton = () => {
    if (currentView === 'distributorsView') {
      setCurrentView('banksView');
      dispatch(
        setAtcSearchState({
          bankId: null
        })
      );
    } else if (currentView === 'atcsView') {
      setCurrentView('distributorsView');
      dispatch(
        setAtcSearchState({
          distributorId: null
        })
      );
    }
  };

  const handleOnClickBank = bankId => {
    setCurrentBankId(bankId);
    setCurrentView('distributorsView');
    dispatch(
      setAtcSearchState({
        bankId: bankId
      })
    );
  };

  const handleOnClickDistributor = distributorId => {
    setCurrentView('atcsView');
    setCurrentDistributorId(distributorId);
    dispatch(
      setAtcSearchState({
        distributorId: distributorId
      })
    );
  };

  const getBanksView = () => {
    // Find the Merchants product vault data
    const banksData = [];
    const seenBankIds = new Set();
    for (const item of getViewableAtcs(atcs)) {
      if (!seenBankIds.has(item.bank.id)) {
        banksData.push(item.bank);
        seenBankIds.add(item.bank.id);
      }
    }

    return (
      <Row className="text-center justify-content-start">
        {banksData.map(item => {
          return (
            <Col
              key={`bank-entity-${item.id}`}
              xs={6}
              md={4}
              lg={3}
              xxl={2}
              className="m-2"
            >
              <AtcSearchEntityItem
                entity={item}
                handleOnClick={handleOnClickBank}
              />
            </Col>
          );
        })}
      </Row>
    );
  };

  const getDistributorsView = () => {
    // Find the Merchants product vault data
    const distributorsData = [];
    const seenDistributorIds = new Set();
    for (const item of getViewableAtcs(atcs)) {
      if (
        item.bank.id === currentBankId &&
        !seenDistributorIds.has(item.distributor.id)
      ) {
        distributorsData.push(item.distributor);
        seenDistributorIds.add(item.distributor.id);
      }
    }

    return (
      <Row className="text-center justify-content-start">
        {distributorsData.map(item => {
          return (
            <Col
              key={`distributor-entity-${item.id}`}
              xs={6}
              md={4}
              lg={3}
              xxl={2}
              className="m-2"
            >
              <AtcSearchEntityItem
                entity={item}
                handleOnClick={handleOnClickDistributor}
              />
            </Col>
          );
        })}
      </Row>
    );
  };

  const getAtcItemsView = () => {
    // Find the ATCs issued to a specifc bank and distributor
    let atcData = [];
    for (const item of getViewableAtcs(atcs)) {
      if (
        item.bank.id === currentBankId &&
        item.distributor.id === currentDistributorId
      ) {
        atcData.push(item);
      }
    }

    return (
      <Row className="text-center g-4 px-2">
        {atcData.map(item => (
          <Col key={`atc-item-${item.id}`} xs={12} md={6} lg={6} xxl={4}>
            <AtcItem item={item} shouldLink={false} />
          </Col>
        ))}
      </Row>
    );
  };

  const getView = view => {
    if (view === 'banksView') {
      return getBanksView();
    } else if (view === 'distributorsView') {
      return getDistributorsView();
    } else if (view === 'atcsView') {
      return getAtcItemsView();
    }
    return null;
  };

  const getCurrentBankTag = () => {
    if (
      currentBankId &&
      (currentView === 'distributorsView' || currentView === 'atcsView')
    ) {
      let currentBank = null;
      for (const item of Object.values(banks)) {
        if (item.id === currentBankId) {
          currentBank = item;
        }
      }

      if (!currentBank) {
        return null;
      }

      return (
        <div className="card ms-3">
          <div className="d-flex">
            <div className="me-auto">
              <Image
                fluid
                style={{ height: '2rem' }}
                src={currentBank.logo}
                className="p-1"
              />
            </div>
            <div className="ms-auto d-flex align-items-center">
              <div className="card-body d-flex p-0">
                <p className="card-text mx-1">{currentBank.name}</p>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  const getCurrentDistributorTag = () => {
    if (currentDistributorId && currentView === 'atcsView') {
      let currentDistributor = null;
      for (const item of Object.values(distributors)) {
        if (item.id === currentDistributorId) {
          currentDistributor = item;
        }
      }

      if (!currentDistributor) {
        return null;
      }

      return (
        <div className="card ms-3">
          <div className="d-flex">
            <div className="me-auto">
              <Image
                fluid
                style={{ height: '2rem' }}
                src={currentDistributor.logo}
                className="p-1"
              />
            </div>
            <div className="ms-auto d-flex align-items-center">
              <div className="card-body d-flex p-0">
                <p className="card-text mx-1">{currentDistributor.name}</p>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <Card>
      <Card.Header className="bg-light">
        <div className="d-flex align-items-center g-1">
          <div className="d-flex align-items-center">
            <IconButton
              onClick={onClickBackButton}
              variant="falcon-default"
              size="sm"
              icon="arrow-left"
              className={`me-3 ${showBackButton() ? null : 'd-none'}`}
            />
            <h5 className="mb-0">Search All ATCs </h5>
          </div>
          <div className="ms-auto me-2 d-flex justify-content-end align-items-center">
            <div className="flex-grow-1" style={{ width: '30rem' }}>
              <Form.Control placeholder="Search..." size="sm" />
            </div>
            {getCurrentBankTag()}
            {getCurrentDistributorTag()}
          </div>
        </div>
      </Card.Header>
      <Card.Body className="bg-light px-1 pb-1 pt-0 fs--1">
        <hr className="mt-0 mx-2" />
        {getView(currentView)}
        <hr className="mx-2" />
      </Card.Body>
    </Card>
  );
};

MerchantAtcSearch.propTypes = {
  merchantId: PropTypes.number.isRequired
};

export default MerchantAtcSearch;
