export default [
  {
    id: 1,
    avatar: {
      emoji: '🔍',
      size: 'xl'
    },
    children:
      '<strong>Ahmed Bello</strong> assigned you a new <strong>Loan Application</strong>!',
    time: 'Just Now'
  },
  {
    id: 2,
    avatar: {
      emoji: '📌',
      size: 'xl'
    },
    children:
      '<strong>Bola Adebayo</strong> issued a <strong>Loan Offer</strong>!',
    time: 'Yesterday'
  },
  {
    id: 3,
    avatar: {
      emoji: '🏷️',
      size: 'xl'
    },
    children:
      '<strong>Chiaza Kalu</strong> created a new <strong>Pickup Authorization</strong>!',
    time: 'March 1, 8:00 PM'
  },
  {
    id: 4,
    avatar: {
      emoji: '💬',
      size: 'xl'
    },
    children:
      '<strong>Dangote Cement</strong> issued new <strong>Digital Product Stock</strong>!',
    time: 'Februrary 27, 12:00 AM'
  }
];
