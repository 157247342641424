import React from 'react';
import { Row, Col } from 'react-bootstrap';
import PageGreeting from 'components/common/PageGreeting';

const membersQuickLinks = [
  {
    title: 'Manage Permissions',
    text: 'Control who sees and does what',
    icon: 'user-lock',
    color: 'primary'
  },
  {
    title: 'See Member Activity',
    text: 'See what your team has been up to! ',
    icon: 'history',
    color: 'warning'
  }
];

const OrganizationMembersPage = () => {
  return (
    <>
      <Row className="g-3 mb-3">
        <Col>
          <PageGreeting
            title="Organization Members Home"
            subtitle="Here are some links to get you started,"
            quickLinks={membersQuickLinks}
          />
        </Col>
      </Row>
    </>
  );
};

export default OrganizationMembersPage;
