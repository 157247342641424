import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logoutImg from 'assets/img/icons/spot-illustrations/45.png';

import { useDispatch } from 'react-redux';
import { logout } from 'redux/slices/authSlice';

let didInit = false;

const LogoutPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!didInit) {
      didInit = true;
      dispatch(logout());
    }
  }, []);
  return (
    <>
      <div className="text-center">
        <img
          className="d-block mx-auto mb-4"
          src={logoutImg}
          alt="shield"
          width={100}
        />
        <h3>See you again!</h3>
        <p>
          Thanks for using Falcon. You are <br className="d-none d-sm-block" />
          now successfully signed out.
        </p>
        <Button
          as={Link}
          color="primary"
          size="sm"
          className="mt-3"
          to="/app/login"
        >
          <FontAwesomeIcon
            icon="chevron-left"
            transform="shrink-4 down-1"
            className="me-1"
          />
          Return to Login
        </Button>
      </div>
    </>
  );
};

export default LogoutPage;
