export const teams = [
  {
    teamName: 'Loan Approval',
    memberCount: 12
  },
  {
    teamName: 'Risk Managment',
    memberCount: 7
  },
  {
    teamName: 'Tech Admin',
    memberCount: 3
  },
  {
    teamName: 'Credit Management',
    memberCount: 10
  }
];
