import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { teams } from 'data/organizations/teams';
import TeamsPreview from './TeamsPreview';
import activities from 'data/activities';
import ActivityLog from './ActivityLog';
import TodoPreview from './TodoPreview';
import todos from 'data/app/todos';
import coverSrc from 'assets/img/generic/4.jpg';
import avatar from 'assets/img/team/avatar.png';
import VerifiedBadge from 'components/common/VerifiedBadge';
import ProfileBanner from '../ProfileBanner';

const ProfilePage = () => {
  return (
    <>
      <ProfileBanner>
        <ProfileBanner.Header avatar={avatar} coverSrc={coverSrc} />
        <ProfileBanner.Body>
          <Row>
            <Col lg={8}>
              <h4 className="mb-1">
                Joshua Kalu <VerifiedBadge />
              </h4>
              <h5 className="fs-0 fw-normal">Senior Credit manager</h5>
              <p className="text-500">Lagos, Nigeria</p>
              <div className="border-dashed border-bottom my-4 d-lg-none" />
            </Col>
          </Row>
        </ProfileBanner.Body>
      </ProfileBanner>
      <Row className="g-3 mb-3">
        <Col lg={8}>
          <TeamsPreview teams={teams.slice(0, 4)} colBreakpoints={{ sm: 6 }} />
          <ActivityLog className="mt-3" activities={activities.slice(0, 4)} />
        </Col>
        <Col lg={4}>
          <div className="sticky-sidebar">
            <TodoPreview className="mb-3" todos={todos.slice(0, 3)} />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ProfilePage;
