import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import envelope from 'assets/img/icons/spot-illustrations/16.png';

const ConfirmMailPage = () => {
  return (
    <>
      <div className="text-center">
        <img
          className="d-block mx-auto mb-4"
          src={envelope}
          alt="sent"
          width={100}
        />
        <h3>Please check your email!</h3>
        <p>
          An email has been sent to <strong>{'abc@xyz.com'}</strong>. Please
          click on the included link to reset your password.
        </p>
        <Button
          as={Link}
          color="primary"
          size="sm"
          className="mt-3"
          to="/app/login"
        >
          <FontAwesomeIcon
            icon="chevron-left"
            transform="shrink-4 down-1"
            className="me-1"
          />
          Return to login
        </Button>
      </div>
    </>
  );
};

export default ConfirmMailPage;
