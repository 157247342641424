import React, { useState, useEffect } from 'react';
import { Card, Nav, Tab } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import {
  updateMerchantApprovalWorkflow,
  updateBankIssuingWorkflow,
  atcStatus
} from 'redux/slices/atcSlice';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SimpleBar from 'simplebar-react';
import SubPageLayout from 'layouts/SubPageLayout';

import ProductPickupTabPane from './common/productPickupTabPane';
import ApprovalWorkflowTabPane from './common/approvalWorkflowTabPane';
import SummaryTabPane from './common/summaryTabPane';
import AtcQuickActions from './common/atcQuickActions';

const ViewAtcPage = () => {
  const { atcId } = useParams();
  const navigate = useNavigate();

  const { orgProfile, orgId } = useSelector(state => state.config);
  const atcs = useSelector(state => state.atc.atcs);
  const dispatch = useDispatch();

  const [currentTab, setCurrentTab] = useState('summary');

  useEffect(() => {
    // Make sure atcId is a number if not do not load page
    if (!atcId || isNaN(Number(atcId))) {
      navigate('/app/not-found');
    }

    // Make sure atc exists
    if (!Object.hasOwn(atcs, atcId)) {
      navigate('/app/not-found');
    }

    // Make sure the current atc belongs to and can be viewed
    // by the current org
    if (
      orgProfile === 'bank' &&
      (atcs[atcId].bank.id !== orgId ||
        atcs[atcId].status < atcStatus.releasedToBank)
    ) {
      navigate('/app/not-found');
    } else if (orgProfile === 'merchant' && atcs[atcId].merchant.id !== orgId) {
      navigate('/app/not-found');
    } else if (
      orgProfile === 'distributor' &&
      (atcs[atcId].distributor.id !== orgId ||
        atcs[atcId].status < atcStatus.releasedToDistributor)
    ) {
      navigate('/app/not-found');
    }
  }, [atcId]);

  const getApprovalWorkFlowTabNav = orgProfile => {
    if (orgProfile === 'bank' || orgProfile === 'merchant') {
      return (
        <Nav.Item>
          <Nav.Link
            eventKey="approval-workflow"
            onClick={() => setCurrentTab('approval-workflow')}
            className="d-flex align-items-center py-3 px-x1 mb-0"
          >
            <FontAwesomeIcon
              icon="check-square"
              className="text-600 tab-icon"
            />
            <h6 className="text-600 mb-0 ms-1">Approval Workflow</h6>
          </Nav.Link>
        </Nav.Item>
      );
    }

    return null;
  };

  const getApprovalWorkFlowTabPane = orgProfile => {
    if (orgProfile === 'bank') {
      return (
        <Tab.Pane eventKey="approval-workflow">
          <ApprovalWorkflowTabPane
            workflow={atcs[atcId].bankIssuingWorkflow}
            updateWorkflowCallback={newStep => {
              dispatch(updateBankIssuingWorkflow({ atcId, newStep }));
            }}
          />
        </Tab.Pane>
      );
    } else if (orgProfile === 'merchant') {
      return (
        <Tab.Pane eventKey="approval-workflow">
          <ApprovalWorkflowTabPane
            workflow={atcs[atcId].merchantApprovalWorkflow}
            updateWorkflowCallback={newStep => {
              dispatch(updateMerchantApprovalWorkflow({ atcId, newStep }));
            }}
          />
        </Tab.Pane>
      );
    }

    return null;
  };

  return (
    <>
      <SubPageLayout
        back="/app/supplychain/atc"
        buttons={[]}
        title={`ATC #${atcId}`}
      >
        <AtcQuickActions atc={atcs[atcId]} />
        <Card>
          <Tab.Container activeKey={currentTab}>
            <SimpleBar>
              <Card.Header className="p-0 border-bottom">
                <Nav className="nav-tabs border-0 flex-nowrap tab-contact-details">
                  <Nav.Item>
                    <Nav.Link
                      eventKey="summary"
                      onClick={() => setCurrentTab('summary')}
                      className="d-flex align-items-center py-3 px-x1 mb-0"
                    >
                      <FontAwesomeIcon
                        icon="stream"
                        className="text-600 tab-icon"
                      />
                      <h6 className="text-600 mb-0 ms-1">Summary</h6>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="product-pickup"
                      onClick={() => setCurrentTab('product-pickup')}
                      className="d-flex align-items-center py-3 px-x1 mb-0"
                    >
                      <FontAwesomeIcon
                        icon="truck"
                        className="text-600 tab-icon"
                      />
                      <h6 className="text-600 mb-0 ms-1">Product Pickup</h6>
                    </Nav.Link>
                  </Nav.Item>
                  {getApprovalWorkFlowTabNav(orgProfile)}
                </Nav>
              </Card.Header>
            </SimpleBar>
            <Card.Body className="bg-light">
              <Tab.Content>
                <Tab.Pane eventKey="summary">
                  <SummaryTabPane
                    atc={atcs[atcId]}
                    setToApprovalTab={() => setCurrentTab('approval-workflow')}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="product-pickup">
                  <ProductPickupTabPane atc={atcs[atcId]} />
                </Tab.Pane>
                {getApprovalWorkFlowTabPane(orgProfile)}
              </Tab.Content>
            </Card.Body>
          </Tab.Container>
        </Card>
      </SubPageLayout>
    </>
  );
};

export default ViewAtcPage;
