import React from 'react';
import ProfileBanner from '../ProfileBanner';
import coverSrc from 'assets/img/generic/4.jpg';
import avatar from 'assets/img/team/avatar.png';
import { Col, Row } from 'react-bootstrap';
import ProfileSettings from './ProfileSettings';
import AccountSettings from './AccountSettings';
import ChangePassword from './ChangePassword';
import VerifiedBadge from 'components/common/VerifiedBadge';

const SettingsPage = () => {
  return (
    <>
      <ProfileBanner>
        <ProfileBanner.Header avatar={avatar} coverSrc={coverSrc} />
        <ProfileBanner.Body>
          <Row>
            <Col lg={8}>
              <h4 className="mb-1">
                Joshua Kalu <VerifiedBadge />
              </h4>
              <h5 className="fs-0 fw-normal">Senior Credit manager</h5>
              <p className="text-500">Lagos, Nigeria</p>
              <div className="border-dashed border-bottom my-4 d-lg-none" />
            </Col>
          </Row>
        </ProfileBanner.Body>
      </ProfileBanner>
      <Row className="g-3">
        <Col lg={8}>
          <ProfileSettings />
        </Col>
        <Col lg={4}>
          <div className="sticky-sidebar">
            <AccountSettings />
            <ChangePassword />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default SettingsPage;
