import {
  canViewSupplyChainRoute,
  canViewAppRoute,
  canViewOrganizationRoute
} from './viewPermissions';

import React from 'react';

import RequireAuth from './requireAuth';

import MainLayout from '../layouts/MainLayout';
import AuthLayout from '../layouts/AuthLayout';
import ErrorLayout from '../layouts/ErrorLayout';

import Error404 from 'pages/errors/Error404';

import LoginPage from 'pages/auth/loginPage';
import LogoutPage from 'pages/auth/logoutPage';
import RegistrationPage from 'pages/auth/registrationPage';
import ForgotPasswordPage from 'pages/auth/forgotPasswordPage';
import PasswordResetPage from 'pages/auth/passwordResetPage';
import ConfirmMailPage from 'pages/auth/confirmMailPage';

import HomePage from 'pages/home/homePage';

import NotificationPage from 'pages/app/notifications/notificationsPage';
import SettingsPage from 'pages/app/settings/settingsPage';
import ProfilePage from 'pages/app/profile/profilePage';

import OrganizationBranchesPage from 'pages/organizations/branches/organizationBranchesPage';
import OrganizationMembersPage from 'pages/organizations/members/organizationMembersPage';
import OrganizationProfilePage from 'pages/organizations/profile/organizationProfilePage';
import OrganizationTeamsPage from 'pages/organizations/teams/organizationTeamsPage';

import AtcCenterPage from 'pages/supply-chain/atcs/atcCenterPage';
import CreateAtcPage from 'pages/supply-chain/atcs/createAtcPage';
import ViewAtcPage from 'pages/supply-chain/atcs/viewAtcPage';

import DistributorsHomePage from 'pages/supply-chain/distributors/distributorsHomePage';

const siteRoutes = [
  {
    layout: <AuthLayout />,
    routeGroups: [
      {
        showInNav: false,
        routes: [
          {
            to: '/app/login',
            canView: canViewAppRoute(),
            element: <LoginPage />,
            requireAuth: false
          },
          {
            to: '/app/logout',
            canView: canViewAppRoute(),
            element: <LogoutPage />,
            requireAuth: false
          },
          {
            to: '/app/register',
            canView: canViewAppRoute(),
            element: <RegistrationPage />,
            requireAuth: false
          },
          {
            to: '/app/forgot-password',
            canView: canViewAppRoute(),
            element: <ForgotPasswordPage />,
            requireAuth: false
          },
          {
            to: '/app/reset-password',
            canView: canViewAppRoute(),
            element: <PasswordResetPage />,
            requireAuth: false
          },
          {
            to: '/app/confirm-mail',
            canView: canViewAppRoute(),
            element: <ConfirmMailPage />,
            requireAuth: false
          }
        ]
      }
    ]
  },
  {
    layout: <MainLayout />,
    routeGroups: [
      {
        showInNav: false,
        routes: [
          {
            to: '/app',
            canView: canViewAppRoute(),
            element: <HomePage />,
            requireAuth: true
          }
        ]
      },
      {
        label: 'Supply Chain',
        showInNav: true,
        routes: [
          {
            name: 'ATC Center',
            icon: 'vote-yea',
            to: '/app/supplychain/atc',
            active: true,
            canView: canViewSupplyChainRoute([
              'bank',
              'merchant',
              'distributor'
            ]),
            showInNav: true,
            element: <AtcCenterPage />,
            requireAuth: true
          },
          {
            to: '/app/supplychain/atc/create',
            canView: canViewSupplyChainRoute(['merchant']),
            showInNav: false,
            element: <CreateAtcPage />,
            requireAuth: true
          },
          {
            to: '/app/supplychain/atc/view/:atcId',
            canView: canViewSupplyChainRoute([
              'bank',
              'merchant',
              'distributor'
            ]),
            showInNav: false,
            element: <ViewAtcPage />,
            requireAuth: true
          },
          {
            name: 'Distributors',
            icon: 'truck',
            to: '/app/supplychain/distributors',
            active: true,
            canView: canViewSupplyChainRoute(['merchant']),
            showInNav: true,
            element: <DistributorsHomePage />,
            requireAuth: true
          }
        ]
      },
      {
        label: 'Manage Organizations',
        showInNav: true,
        routes: [
          {
            name: 'Members',
            icon: 'user-plus',
            to: '/app/organizations/members',
            active: true,
            canView: canViewOrganizationRoute(),
            showInNav: true,
            element: <OrganizationMembersPage />,
            requireAuth: true
          },
          {
            name: 'Teams',
            icon: 'users',
            to: '/app/organizations/teams',
            active: true,
            canView: canViewOrganizationRoute(),
            showInNav: true,
            element: <OrganizationTeamsPage />,
            requireAuth: true
          },
          {
            name: 'Branches',
            icon: 'globe-africa',
            to: '/app/organizations/branches',
            active: true,
            canView: canViewOrganizationRoute(),
            showInNav: true,
            element: <OrganizationBranchesPage />,
            requireAuth: true
          },
          {
            name: 'Organization Profile',
            icon: 'building',
            to: '/app/organizations/profile',
            active: true,
            canView: canViewOrganizationRoute(),
            showInNav: true,
            element: <OrganizationProfilePage />,
            requireAuth: true
          }
        ]
      },
      {
        label: 'App',
        showInNav: true,
        routes: [
          {
            name: 'Notifications',
            icon: 'bell',
            to: '/app/notifications',
            active: true,
            canView: canViewAppRoute(),
            showInNav: true,
            element: <NotificationPage />,
            requireAuth: true
          },
          {
            name: 'Settings',
            icon: 'cog',
            to: '/app/settings',
            active: true,
            canView: canViewAppRoute(),
            showInNav: true,
            element: <SettingsPage />,
            requireAuth: true
          },
          {
            name: 'Profile',
            icon: 'user-circle',
            to: '/app/profile',
            active: true,
            canView: canViewAppRoute(),
            showInNav: true,
            element: <ProfilePage />,
            requireAuth: true
          }
        ]
      }
    ]
  }
];

export const getRoutesForNav = currentOrgProfile => {
  let navRoutes = [];

  siteRoutes.forEach(layout => {
    layout.routeGroups.forEach(routeGroup => {
      if (routeGroup.showInNav) {
        let navRoutesGroup = { label: routeGroup.label, children: [] };
        routeGroup.routes.forEach(route => {
          if (route.showInNav && route.canView(currentOrgProfile)) {
            // Add route with only needed props
            navRoutesGroup.children.push({
              name: route.name,
              icon: route.icon,
              to: route.to,
              active: route.active
            });
          }
        });

        if (navRoutesGroup.children.length > 0) {
          navRoutes.push(navRoutesGroup);
        }
      }
    });
  });
  return navRoutes;
};

export const getRoutesForReactRouter = currentOrgProfile => {
  let reactRouterRoutes = [];

  siteRoutes.forEach(layout => {
    let layoutRoute = {
      element: layout.layout,
      errorElement: (
        <ErrorLayout>
          <Error404 />
        </ErrorLayout>
      ),
      children: []
    };
    layout.routeGroups.forEach(routeGroup => {
      routeGroup.routes.forEach(route => {
        // Only add route if canView Passes
        if (route.canView(currentOrgProfile)) {
          if (route.requireAuth) {
            layoutRoute.children.push({
              path: route.to,
              element: <RequireAuth>{route.element}</RequireAuth>
            });
          } else {
            layoutRoute.children.push({
              path: route.to,
              element: route.element
            });
          }
        }
      });
    });

    // Add routes to final list
    reactRouterRoutes.push(layoutRoute);
  });

  // Push in catchall/error routes last
  reactRouterRoutes.push({
    element: <ErrorLayout />,
    children: [
      {
        to: '/app/not-found',
        element: <Error404 />
      },
      {
        path: '*',
        element: <Error404 />
      }
    ]
  });
  return reactRouterRoutes;
};

export default siteRoutes;
