import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card } from 'react-bootstrap';

const AtcSearchEntityItem = ({ entity, handleOnClick }) => {
  const { logo, name, id } = entity;
  return (
    <Card style={{ width: '12rem', height: '16rem' }} border="dark">
      <Card.Img variant="top" src={logo} />
      <Card.Body>
        <h6 className="mb-1">
          <Button
            variant="link"
            className="stretched-link text-reset"
            onClick={() => {
              handleOnClick(id);
            }}
          >
            {name}
          </Button>
        </h6>
      </Card.Body>
    </Card>
  );
};

AtcSearchEntityItem.propTypes = {
  entity: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    logo: PropTypes.any.isRequired
  }).isRequired,
  handleOnClick: PropTypes.func.isRequired
};

export default AtcSearchEntityItem;
