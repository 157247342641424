import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isDarkMode: false,
  isNavbarVerticalCollapsed: false,
  showBurgerMenu: false,
  navbarCollapsed: false
};

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    toggleDarkMode: state => {
      state.isDarkMode = !state.isDarkMode;
    },
    setIsNavbarVerticalCollapsed: (state, action) => {
      state.isNavbarVerticalCollapsed = action.payload;
    },
    toggleShowBurgerMenu: state => {
      state.showBurgerMenu = !state.showBurgerMenu;
    }
  }
});

export const {
  toggleDarkMode,
  setIsNavbarVerticalCollapsed,
  toggleShowBurgerMenu
} = themeSlice.actions;

export default themeSlice;
