import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Badge, Card, Image } from 'react-bootstrap';

import { useSelector } from 'react-redux';
import { atcStatus } from 'redux/slices/atcSlice';

const AtcItem = ({ item }) => {
  const { orgProfile } = useSelector(state => state.config);
  const { id, product, totalQuantity, issuedOn, status } = item;

  const getStatusBadge = () => {
    if (status === atcStatus.closed) {
      return (
        <Badge pill bg="secondary">
          Closed
        </Badge>
      );
    } else if (
      (orgProfile === 'merchant' &&
        (status === atcStatus.created ||
          status === atcStatus.approvedByMerchant)) ||
      (orgProfile === 'bank' &&
        (status === atcStatus.releasedToBank ||
          status === atcStatus.approvedByBank))
    ) {
      return (
        <Badge pill bg="warning">
          Pending
        </Badge>
      );
    } else
      return (
        <Badge pill bg="success">
          Released
        </Badge>
      );
  };

  return (
    <Card>
      <Card.Header className="py-2 bg-light mb-0">
        <div className="d-flex justify-content-between">
          <h6>{product.productName}</h6>
          <h6>ATC-#{id}</h6>
        </div>
      </Card.Header>
      <Link
        to={`/app/supplychain/atc/view/${id}`}
        className="fw-semi-bold stretched-link"
      />
      <Card.Body>
        <div className="d-flex align-items-center h-100 justify-content-between">
          <Image
            rounded
            fluid
            src={product.logo}
            style={{ height: '7.5rem' }}
          />
          <div className="text-center mt-3">
            <h6 className="fs-0 mb-1">
              {`Total Quantity: ${totalQuantity} ${product.quantityUnit}`}
            </h6>
            <p className="fs--1 mb-0">{`Product Id: ${product.productId}`}</p>
            {getStatusBadge()}
          </div>
        </div>
      </Card.Body>
      <Card.Footer className="bg-light py-2">
        <div className="d-flex justify-content-end">
          <span className="fs--1 font-sans-serif" to="#!">
            {`Last Updated on ${issuedOn}`}
          </span>
        </div>
      </Card.Footer>
    </Card>
  );
};

AtcItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    product: PropTypes.shape({
      productName: PropTypes.string.isRequired,
      productId: PropTypes.string.isRequired,
      quantityUnit: PropTypes.string.isRequired,
      logo: PropTypes.string.isRequired
    }).isRequired,
    totalQuantity: PropTypes.number.isRequired,
    issuedOn: PropTypes.string.isRequired,
    status: PropTypes.number.isRequired
  })
};

export default AtcItem;
