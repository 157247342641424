export const canViewSupplyChainRoute = orgProfiles => {
  return currentOrgProfile => {
    return orgProfiles.includes(currentOrgProfile);
  };
};

export const canViewOrganizationRoute = () => {
  return () => {
    return true;
  };
};

export const canViewAppRoute = () => {
  return () => {
    return true;
  };
};
